import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';

export default class Years extends Component {

    constructor(props) {
        super(props)
        this.state = {
            years: [],

            add: false,
            edit: false,
            id: false,
            name:'',

            severity: "",
            isAlert:false,
            loading: true,   

            anchorEl: [],               
        }
    }
    
    componentDidMount(){
        document.title = SchoolName() + " | Année academique"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/years', config)
        .then(response => {
            this.setState({ 
                years: response.data.data,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({loading: true})   
            
        const bodyParameters = {
            name: this.state.name
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/years',
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()            }                       
        }).catch(error => {
            this.setState({
                add: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    handleSubmitEdit(event){
        event.preventDefault()
        this.setState({loading: true})   
            
        const bodyParameters = {
            name: this.state.name
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        axios.put(MainUrl() + '/api/v1/year/' + this.state.id,
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()            }                       
        }).catch(error => {
            this.setState({
                edit: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    edit(id){
        const item = this.state.years.filter(c => c["id"] === id)[0]
        this.setState({
            id: id,
            name: item.name,
            edit: true,
            anchorEl: [],   
        })
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/year/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.componentDidMount()     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {

        const {
            years,
            add,
            edit,
            name,
            loading,
            severity,
            message,
            isAlert,
            anchorEl
        } = this.state

        let column = []
        if(this.props.accesses.approve){
            column = [
                { 
                    field: 'name', 
                    headerName: 'Nom',
                    flex: 1
                },
                { 
                    field: 'activated', 
                    headerName: 'Statut',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <Tooltip title={cellValues.row.activated ? "Statut actif" : "Statut non actif"}>
                                <IconButton onClick={() => this.delete(cellValues.row.id)} >   
                                    <Switch checked={cellValues.row.activated} />
                                </IconButton> 
                            </Tooltip>                            
                        )                                    
                    }
                },
                { 
                    field: 'actions', 
                    headerName: 'Actions',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <React.Fragment>
                                <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                    <MoreVertIcon style={{color: "#306bff"}} />
                                </IconButton>

                                <Menu                                                            
                                    id={cellValues.row.id}
                                    anchorEl={anchorEl[cellValues.row.id]}
                                    keepMounted
                                    open={Boolean(anchorEl[cellValues.row.id])}
                                    onClose={() => this.setState({anchorEl: []})}    
                                    PaperProps={{
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 0,
                                            ml:1,
                                            width:150
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {!this.props.accesses.write ||
                                        <MenuItem onClick={() => { this.edit(cellValues.row.id) }} disabled={!this.props.accesses.write}>
                                            <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                        </MenuItem>
                                    }                                
                                </Menu>
                            </React.Fragment>
                        )
                    }
                }
            ]
        }else{
            column = [
                { 
                    field: 'name', 
                    headerName: 'Nom',
                    flex: 1
                },
                { 
                    field: 'actions', 
                    headerName: 'Actions',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <React.Fragment>
                                <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                    <MoreVertIcon style={{color: "#306bff"}} />
                                </IconButton>

                                <Menu                                                            
                                    id={cellValues.row.id}
                                    anchorEl={anchorEl[cellValues.row.id]}
                                    keepMounted
                                    open={Boolean(anchorEl[cellValues.row.id])}
                                    onClose={() => this.setState({anchorEl: []})}    
                                    PaperProps={{
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 0,
                                            ml:1,
                                            width:150
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {!this.props.accesses.write ||
                                        <MenuItem onClick={() => { this.edit(cellValues.row.id) }} disabled={!this.props.accesses.write}>
                                            <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                        </MenuItem>
                                    }                                
                                </Menu>
                            </React.Fragment>
                        )
                    }
                }
            ]
        }

        let DataYears=years
        if(this.props.search !== ''){
            DataYears = years.filter(elem => { return elem.name.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    {!this.props.accesses.write || 
                        <Button 
                            variant="contained" 
                            disabled={!this.props.accesses.write} 
                            onClick={() => this.setState({add: true})} 
                            sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff'}}
                        >
                            Ajouter une année académique
                        </Button>    
                    }
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={DataYears}
                            columns={column}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Ligne par page'
                                }
                            }}
                        />
                    </Card>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter une année académique
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de l'année academique"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>   
                    </form>

                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Éditer une année académique
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmitEdit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de l'année academique"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>
                
                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }
}