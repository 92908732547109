import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import SelectClasse from '../SelectClasse'
import DeleteIcon from '@mui/icons-material/Delete';

export default class Presences extends Component{

    constructor(props) {
        super(props)
        this.state = {
            presences: [],
            teachers: [],
            subjectYears: [],

            add: false,
            edit: false,
            id: null,
            classe_id: null,
            subject_id: null,
            teacher_id: null,
            date: new Date(),
            etablissement: 'LBFC',
            hour: "",
            nbhours: 1,
            activated: true,
            taux: 1500,


            severity: "",
            message: "",
            isAlert:false,
            loading: true,
            anchorEl: [],
            anchorElDate: null,
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Présences"
        this.setState({loading: false}) 
    }

    handleChangeClasse(data){
        if(data.name === 'classe_id'){
            this.setState({ 
                classe_id: data.classe_id,
                loading: true
            }) 
            this.getData(data.classe_id)
        }        
    }

    getData(id){ 
        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: id,
        }
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/presences', 
            bodyParameters, 
            config
        )
        .then(response => {
            console.log('reee', response.data)
            this.setState({ 
                presences: response.data.data,
                teachers: response.data.teachers,
                subjectYears: response.data.subjectYears,
                loading: false,
            });
        }).catch(error => {
            console.log('error', error)
            this.setState({
                loading: false,
                severity: 'error',
                //message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleClickDate(event){this.setState({anchorElDate: event.currentTarget})}

    handleSelectDate(date){ this.setState({date: date, anchorElDate: null}) }


    handleSubmit(event) {
        event.preventDefault()       
        this.setState({loading: true})   

        let elem = this.state.teachers.filter(c => c["id"] === this.state.teacher_id)[0]

        const bodyParameters = {
            year_id: this.props.year_id,
            teacher_id: this.state.teacher_id,
            classe_id: this.state.classe_id,
            subject_id: this.state.subject_id,
            date: this.state.date,
            hour: this.state.hour,            
            nbhours: this.state.nbhours,
            activated: this.state.activated,
            etablissement: this.state.etablissement,
            taux: this.state.taux,
            user_id: elem.user_id
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/presence',
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.getData(this.state.classe_id)         
            }                       
        }).catch(error => {
            this.setState({
                add: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    handleSubmitEdit(event){
        event.preventDefault()        
        this.setState({loading: true})   

        let elem = this.state.teachers.filter(c => c["id"] === this.state.teacher_id)[0]
                
        const bodyParameters = {
            year_id: this.props.year_id,
            teacher_id: this.state.teacher_id,
            classe_id: this.state.classe_id,
            subject_id: this.state.subject_id,
            date: this.state.date,
            hour: this.state.hour,            
            nbhours: this.state.nbhours,
            activated: this.state.activated,
            etablissement: this.state.etablissement,
            taux: this.state.taux,
            user_id: elem.user_id
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        axios.put(MainUrl() + '/api/v1/presence/' + this.state.id,
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.getData(this.state.classe_id)          
            }                       
    }).catch(error => {
            this.setState({
                edit: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    edit(id){
        const item = this.state.presences.filter(c => c["id"] === id)[0]

        this.setState({
            id: id,
            subject_id: item.subject_id,
            teacher_id: item.teacher_id,
            date: new Date(item.date),
            etablissement: item.etablissement,
            hour: item.hour,
            nbhours: item.nbhours,
            activated: item.activated,
            taux: item.taux,
            edit: true,
            anchorEl: [],   
        })
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/presence/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.getData(this.state.classe_id)         
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {  
        
        const {
            presences,
            teachers,
            subjectYears,
            add,
            edit,
            teacher_id,
            etablissement,
            date,
            hour,
            nbhours,
            classe_id,
            subject_id,
            activated,
            taux,
            loading,
            severity,
            message,
            isAlert,
            anchorEl,
            anchorElDate,
        } = this.state 

        const column = [
            { 
                field: 'matricule', 
                headerName: 'Matricule',
                flex: 2/3,
                renderCell: (cellValues) => { return cellValues.row.user.matricule}
            },
            { 
                field: 'user', 
                headerName: 'Professeurs',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.user.first_name + " " + cellValues.row.user.second_name}
            },
            { 
                field: 'date', 
                headerName: 'Date',
                flex: 1,
                renderCell: (cellValues) => { return displaydate(new Date(cellValues.row.date))}
            },
            { 
                field: 'hour', 
                headerName: 'Heure',
                flex: 2/3,
            },
            { 
                field: 'subject_id', 
                headerName: 'Matière',
                flex: 2/3,
                renderCell: (cellValues) => { return cellValues.row.subject.name}
            },
            { 
                field: 'nbhours', 
                headerName: "Nb d'heure",
                flex: 2/3,
            },
            { 
                field: 'activated', 
                headerName: 'Etat',
                flex: 2/3,
                renderCell: (cellValues) => {
                    if(cellValues.row.activated){
                        return <span style={{color:"green", fontSize:16 }}>Effectuée</span>
                    }else{
                        return <span style={{color:"red", fontSize:16 }}>Non effectuée</span>
                    }                
                }
            },
            { 
                field: 'etablissement', 
                headerName: "Etablissement",
                flex: 2/3,
            },
            { 
                field: 'taux', 
                headerName: 'Taux horaire',
                flex: 2/3,
                renderCell: (cellValues) => { return cellValues.row.taux.toLocaleString()}
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                        width:150
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                {!this.props.accesses.write ||
                                    <MenuItem onClick={() => { this.edit(cellValues.row.id) }}>
                                        <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                    </MenuItem>
                                }

                                {!this.props.accesses.remove ||
                                    <MenuItem onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(cellValues.row.id)}}} >
                                        <ListItemIcon sx={{color: 'red'}}><DeleteIcon fontSize="small" /></ListItemIcon>Supprimer
                                    </MenuItem>
                                }
                            </Menu>
                        </React.Fragment>
                    )
                }
            }

        ]

        let dataPresence = []
        let subjectYear = []
        if(this.props.year_id !== null){
            dataPresence = presences.filter(c => c["year_id"] === this.props.year_id)     
            subjectYear = subjectYears.filter(c => c["year_id"] === this.props.year_id)            
        } 
        if(classe_id !== null){
            dataPresence = dataPresence.filter(c => c["classe_id"] === classe_id)
            subjectYear = subjectYear.filter(c => c["classe_id"] === classe_id)
            subjectYear = subjectYear.filter( (ele, ind) => ind === subjectYear.findIndex(elem => elem.subject_id === ele.subject_id))    
        }

        if(this.props.search !== ''){
            let result = []
            dataPresence.map((elem, index) => {
                elem.search = elem.user.matricule + ' ' + elem.user.first_name + ' ' + elem.user.second_name + ' ' +  elem.subject.name
                result = result.concat(elem)
            })
            dataPresence = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        const displaydate = (value) => {
            var date = new Date(value);
            if(date.getMonth() == 0){
                return date.getDate() + "/01/" + date.getFullYear()
            }
            if(date.getMonth() == 1){
                return date.getDate() + "/02/" + date.getFullYear()
            }
            if(date.getMonth() == 2){
                return date.getDate() + "/03/" + date.getFullYear()
            }
            if(date.getMonth() == 3){
                return date.getDate() + "/04/" + date.getFullYear()
            }
            if(date.getMonth() == 4){
                return date.getDate() + "/05/" + date.getFullYear()
            }
            if(date.getMonth() == 5){
                return date.getDate() + "/06/" + date.getFullYear()
            }
            if(date.getMonth() == 6){
                return date.getDate() + "/07/" + date.getFullYear()
            }
            if(date.getMonth() == 7){
                return date.getDate() + "/08/" + date.getFullYear()
            }
            if(date.getMonth() == 8){
                return date.getDate() + "/09/" + date.getFullYear()
            }
            if(date.getMonth() == 9){
                return date.getDate() + "/10/" + date.getFullYear()
            }
            if(date.getMonth() == 10){
                return date.getDate() + "/11/" + date.getFullYear()
            }
            if(date.getMonth() == 11){
                return date.getDate() + "/12/" + date.getFullYear()
            }
        }

        return ( 
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    <SelectClasse 
                        year_id={this.props.year_id} 
                        onChange={this.handleChangeClasse.bind(this)} 
                        width={200} 
                        mr={2} 
                    />
                    
                    {!this.props.accesses.write ||
                        <Button 
                            disabled={classe_id === null} 
                            variant="contained"
                            onClick={() => this.setState({add: true})} 
                            sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff'}}
                        >
                            Ajouter une présence
                        </Button>  
                    }  
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:620, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={dataPresence}
                            columns={column}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Ligne par page'
                                }
                            }}
                        />
                    </Card>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter une présence
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Professeurs"
                                        name="teacher_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={teacher_id || ''}
                                    >
                                        {teachers.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.user.matricule} | {option.user.first_name} {option.user.second_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>                                    
                                    <TextField
                                        id="outlined-required"
                                        label="Date"
                                        style={{ width: '100%' }}
                                        value={displaydate(date)}
                                        onClick={this.handleClickDate.bind(this)} 
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Heure"
                                        name="hour"
                                        style={{ width: '100%' }}
                                        value={hour}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Matière"
                                        name="subject_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={subject_id || ''}
                                    >
                                        {subjectYear.map((item) => {
                                            return <MenuItem value={item.subject_id}>{item.subject.name}</MenuItem>
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Nombre d'heure"
                                        name="nbhours"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={nbhours}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Etat"
                                        name="activated"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={activated}
                                    >
                                        <MenuItem key="activateds" value={true}>Effectuée</MenuItem>
                                        <MenuItem key="activateds" value={false}>Non Effectuée</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Taux horaire"
                                        name="taux"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={taux}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Etablissement"
                                        name="etablissement"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={etablissement}
                                    >
                                        <MenuItem key="IFTICA" value="IFTICA">IFTICA</MenuItem>
                                        <MenuItem key="LBFC" value="LBFC">LBFC</MenuItem>
                                    </TextField>
                                </Grid>
                            
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Éditer une présence
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmitEdit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Professeurs"
                                        name="teacher_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={teacher_id || ''}
                                    >
                                        {teachers.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.user.matricule} | {option.user.first_name} {option.user.second_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>                                    
                                    <TextField
                                        id="outlined-required"
                                        label="Date"
                                        style={{ width: '100%' }}
                                        value={displaydate(date)}
                                        onClick={this.handleClickDate.bind(this)} 
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Heure"
                                        name="hour"
                                        style={{ width: '100%' }}
                                        value={hour}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Matière"
                                        name="subject_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={subject_id || ''}
                                    >
                                        {subjectYear.map((item) => {
                                            return <MenuItem value={item.subject_id}>{item.subject.name}</MenuItem>
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Nombre d'heure"
                                        name="nbhours"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={nbhours}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Etat"
                                        name="activated"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={activated}
                                    >
                                        <MenuItem key="activateds" value={1}>Effectuée</MenuItem>
                                        <MenuItem key="activateds" value={0}>Non Effectuée</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Taux horaire"
                                        name="taux"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={taux}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Etablissement"
                                        name="etablissement"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={etablissement || ''}
                                    >
                                        <MenuItem key="IFTICA" value="IFTICA">IFTICA</MenuItem>
                                        <MenuItem key="LBFC" value="LBFC">LBFC</MenuItem>
                                    </TextField>
                                </Grid>
                            
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>

                <Menu
                    elevation={12}
                    id="basic-menu"
                    anchorEl={anchorElDate}
                    open={Boolean(anchorElDate)}
                    onClose={() => {this.setState({ anchorElDate: null})}}
                    PaperProps={{
                        sx: {
                            mt:4,
                            ml: '-16px',
                        }
                    }}
                >
                    <Calendar
                        date={date}
                        onChange={this.handleSelectDate.bind(this)}
                        locale={locales['fr']}
                    />
                </Menu>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}