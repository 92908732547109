import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import SelectClasse from '../SelectClasse'
import SelectComposition  from '../SelectComposition'

export default class StudentYear extends Component {

    constructor(props) {
        super(props)
        this.state = {
            subjectYears: [],
            subjects: [],

            add: false,
            subject_id: null,
            classe_id: null,
            cycle_id: null,
            composition_id: null,
            coef: 0,

            anchorEl: [],   
            loading: true,
            severity: "",
            message: "",
            isAlert:false
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Matières"
        this.setState({loading: false}) 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    handleChangeClasse(data){
        if(data.name === 'classe_id'){
            this.setState({ 
                classe_id: data.classe_id,
                cycle_id: data.cycle_id
            }) 
        }        
    }

    handleChangeComposition(data){
        if(data.name === 'composition_id'){
            this.setState({ 
                composition_id: data.value,
                loading: true,
            }) 
            this.getData(data.value)
        }        
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    getData(id){ 
        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: this.state.classe_id,
            composition_id: id
        }
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/subject-years', 
            bodyParameters, 
            config
        )
        .then(response => {
            this.setState({ 
                subjectYears: response.data.data,
                subjects: response.data.subjects,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({loading: true})

        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: this.state.classe_id,
            composition_id: this.state.composition_id,
            subject_id: this.state.subject_id,
            coef: this.state.coef
        }        

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/subject-year',
            bodyParameters,
            config
        ).then(response => {
            this.setState({
                add: false,
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.getData(this.state.composition_id)        
        }).catch(error => {
            this.setState({
                add: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/subject-year/' + id,
            config
        ).then(response => {
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.getData(this.state.composition_id)     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {    

        const {
            subjectYears,
            subjects,
            add,
            coef,
            classe_id,
            cycle_id,
            composition_id,
            anchorEl,
            loading,
            severity,
            message,
            isAlert,
        } = this.state

        const column = [
            { 
                field: 'matière', 
                headerName: 'Matières',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.subject.name}
            },
            { 
                field: 'coef', 
                headerName: 'Coef',
                maxWidth: 60,
                align:'center',
                renderCell: (cellValues) => { return <strong>{cellValues.row.coef}</strong>}
            },
            { 
                field: 'classe', 
                headerName: 'Classe',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.classe.name}
            },
            { 
                field: 'composition', 
                headerName: 'Composition',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.composition.name}
            },
            { 
                field: 'year', 
                headerName: 'Année académique',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.year.name}
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                        width:150
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                {!this.props.accesses.remove ||
                                    <MenuItem 
                                        style={{color:"red"}}
                                        disabled={!this.props.accesses.remove}
                                        onClick={() => {
                                            if(window.confirm("Êtes-vous sûr de vouloir enlever cette matière dans cette classe ?"))
                                            {this.delete(cellValues.row.id)}
                                        }}
                                    >
                                        <ListItemIcon><RemoveCircleOutlineIcon fontSize="small" style={{color:"red"}} /></ListItemIcon>Enlever
                                    </MenuItem>
                                }
                                
                            </Menu>
                        </React.Fragment>
                    )
                }
            }
        ]

        let subjectYear = []
        if(this.props.year_id !== null){
            subjectYear = subjectYears.filter(c => c["year_id"] === this.props.year_id)
            subjectYear = subjectYear.filter(c => c["classe_id"] === this.state.classe_id)
            subjectYear = subjectYear.filter(c => c["composition_id"] === this.state.composition_id)
        }

        if(this.props.search !== ''){
            let result = []
            subjectYear.map((elem, index) => {
                elem.search = elem.subject.name + ' ' + elem.coef
                result = result.concat(elem)
            })
            subjectYear = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        let subject = subjects
        subjectYear.map((elem, index) => {
            subject = subject.filter(c => c["id"] !== elem.subject_id)
        })

        return(
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    <SelectClasse 
                        year_id={this.props.year_id} 
                        onChange={this.handleChangeClasse.bind(this)} 
                        width={200} 
                        mr={2} 
                    />

                    <SelectComposition 
                        year_id={this.props.year_id} 
                        classe_id={classe_id} 
                        cycle_id={cycle_id}
                        onChange={this.handleChangeComposition.bind(this)}
                        width={200}
                        mr={2}
                    />

                    <Button 
                        variant="contained" 
                        disabled={!this.props.accesses.write || classe_id === null || composition_id === null} 
                        onClick={() => this.setState({add: true})} 
                        sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff'}}
                    >
                        Ajouter une matière
                    </Button>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={subjectYear}
                            columns={column}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: { labelRowsPerPage: 'Ligne par page' }
                            }}
                        />
                    </Card>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter une matière
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Matière"
                                        name="subject_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                    >
                                        {subject.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Coefficient"
                                        name="coef"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={coef}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>   
                    </form>

                </Dialog>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}