import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dropzone from 'react-dropzone'
import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogContentText from '@mui/material/DialogContentText';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import CollectionsIcon from '@mui/icons-material/Collections';

export default class StudentsV2 extends Component{

    constructor(props) {
        super(props)
        this.state = {
            students: [],
            tuteurs: [],
            selectedFile: [],
            StudentAbsences: [],

            addImport: false,
            add: false,
            edit: false,
            show: false,
            id: null,
            user_id: null,
            matricule: "",
            first_name: "",
            second_name: "",
            genre: "M",
            birthdate: new Date(),
            birthplace: "",
            father: "",
            mother: "",
            tuteur: null,
            password:"",
            passwordc: "",
            showPassword: false,
            showPasswordc: false,
            cover: "",
            showcover: false,
            miniature: null,
            path:"",
            
            inscriptions: [],
            routines: [],
            CompositionYear: [],
            subjectsYears: [],
            AccountYears: [],
            notes: [],
            Payments: [],

            severity: "",
            message: "",
            isAlert:false,
            loading: true,     

            anchorEl: [],
            anchorElDate: null,
            valuetab: 1
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Étudiants"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/students', config)
        .then(response => {
            this.setState({ 
                students: response.data.data,
                tuteurs: response.data.tuteurs,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    handleClickDate(event){this.setState({anchorElDate: event.currentTarget})}

    handleSelectDate(date){ this.setState({birthdate: date, anchorElDate: null}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangetab(event, newValue){ this.setState({ valuetab: newValue}) }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleSubmit(event) {
        event.preventDefault()
        if(this.state.password !== this.state.passwordc){
            this.setState({ 
                loading: false,
                severity: 'error',
                message: "Les deux mots de passe ne sont pas identiques",
                isAlert: true
            })
        }else{
            this.setState({loading: true})   

            const bodyParameters = {
                matricule: this.state.matricule,
                first_name: this.state.first_name,
                second_name: this.state.second_name,
                genre: this.state.genre,
                birthdate: this.state.birthdate,
                birthplace: this.state.birthplace,
                father: this.state.father,
                mother: this.state.mother,
                password: this.state.password
            }

            const token = JSON.parse(localStorage.getItem("TOKEN"))
            const config = {
                headers: {'Authorization': 'Bearer '+token}
            }
            axios.post(MainUrl() + '/api/v1/students',
                bodyParameters,
                config
            ).then(response => {
                if(response.data.statusCode === 201){
                    this.setState({
                        loading: false,
                        severity: 'error',
                        message: response.data.message,
                        isAlert: true
                    }) 
                } else{
                    this.setState({
                        add: false,
                        loading: false,
                        severity: 'success',
                        message: response.data.message,
                        isAlert: true
                    }) 
                    this.componentDidMount()           
                }                       
            }).catch(error => {
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'error',
                    message: error.response.data?.message || "Une erreur inattendue s'est produite",
                    isAlert: true
                });
            });
        }
    }

    handleSubmitEdit(event){
        event.preventDefault()
        if(this.state.password !== this.state.passwordc){
            this.setState({ 
                loading: false,
                severity: 'error',
                message: "Les deux mots de passe ne sont pas identiques",
                isAlert: true
            })
        }else{
            this.setState({loading: true})   
                
            const bodyParameters = {
                user_id: this.state.user_id,
                matricule: this.state.matricule,
                first_name: this.state.first_name,
                second_name: this.state.second_name,
                genre: this.state.genre,
                birthdate: this.state.birthdate,
                birthplace: this.state.birthplace,
                father: this.state.father,
                mother: this.state.mother,
                password: this.state.password
            }

            const token = JSON.parse(localStorage.getItem("TOKEN"))
            const config = {
                headers: {'Authorization': 'Bearer '+token}
            }

            axios.put(MainUrl() + '/api/v1/student/' + this.state.id,
                bodyParameters,
                config
            ).then(response => {
                if(response.data.statusCode === 201){
                    this.setState({
                        loading: false,
                        severity: 'error',
                        message: response.data.message,
                        isAlert: true
                    }) 
                } else{
                    this.setState({
                        edit: false,
                        loading: false,
                        severity: 'success',
                        message: response.data.message,
                        isAlert: true
                    }) 
                    this.componentDidMount()            
                }                       
            }).catch(error => {
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'error',
                    message: error.response.data?.message || "Une erreur inattendue s'est produite",
                    isAlert: true
                });
            });
        }
    }

    handleSubmitImport(){
        if(this.state.selectedFile.length === 0){
            this.setState({ 
                severity: 'error',
                message: "Faites glisser et déposez un fichier, ou cliquez pour sélectionner un fichier",
                isAlert: true
            })
        }else{
            this.setState({loading: true})   
            const tokenJson = localStorage.getItem("TOKEN");
            const token = JSON.parse(tokenJson);        
            const config = {
                headers: {'Authorization': 'Bearer '+token}
            }
            const bodyParameters = new FormData();
            bodyParameters.append("file", this.state.selectedFile)
            axios.post(MainUrl() +  '/api/v1/students/import', 
                bodyParameters,
                config
            ).then(response => {
                if(response.data.statusCode === 201){
                    this.setState({
                        loading: false,
                        severity: 'error',
                        message: response.data.message,
                        isAlert: true
                    }) 
                } else{
                    this.setState({
                        addImport: false,
                        loading: false,
                        severity: 'success',
                        message: response.data.message,
                        isAlert: true
                    }) 
                    this.componentDidMount()            }                       
            }).catch(error => {
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'error',
                    message: error.response.data?.message || "Une erreur inattendue s'est produite",
                    isAlert: true
                });
            });
        }
    }    

    fileDropImport(acceptedFiles){ this.setState({ selectedFile: acceptedFiles[0] })}

    fileDrop(acceptedFiles){ this.setState({miniature: acceptedFiles[0],  path: URL.createObjectURL(acceptedFiles[0])}) }

    handleSubmitCover(event) {
        event.preventDefault()
        this.setState({loading: true}) 

        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        const bodyParameters = new FormData();
        bodyParameters.append("file", this.state.miniature)
        bodyParameters.append("id", this.state.id)

        axios.post(MainUrl() +  '/api/v1/student-cover', 
            bodyParameters,
            config
        )
        .then(response => {
            console.log('res', response.data)
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    showcover: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()            
            }                       
        }).catch(error => {
            this.setState({
                edit: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    show(id){
        this.setState({loading: true}) 
        const item = this.state.students.filter(c => c["id"] === id)[0]

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.get(MainUrl() + '/api/v1/students/details/' + id,
            config
        )
        .then(response => {
            console.log("data", response.data.StudentAbsences)
            this.setState({ 
                id: id,
                valuetab: 1, 
                user_id: item.user_id,
                matricule: item.user.matricule,
                first_name: item.user.first_name,
                second_name: item.user.second_name,
                genre: item.sexe,
                birthdate: new Date(item.birthdate),
                birthplace: item.birthplace,
                father: item.father,
                mother: item.mother,
                cover: item.cover,

                inscriptions: response.data.inscriptions,
                routines: response.data.routines,

                tuteur: response.data.tuteur,
                
                CompositionYear: response.data.CompositionYears,
                subjectsYears: response.data.subjectsYears,
                notes: response.data.notes,
                StudentAbsences: response.data.StudentAbsences,
                AccountYears: response.data.AccountYears,
                Payments: response.data.Payments,

                show: true,
                loading: false,
                anchorEl: [], 
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        });
    }

    edit(id){
        const item = this.state.students.filter(c => c["id"] === id)[0]

        this.setState({
            id: id,
            user_id: item.user_id,
            matricule: item.user.matricule,
            first_name: item.user.first_name,
            second_name: item.user.second_name,
            genre: item.sexe,
            birthdate: new Date(item.birthdate),
            birthplace: item.birthplace,
            father: item.father,
            mother: item.mother,
            password:"",
            passwordc: "",
            edit: true,
            anchorEl: [],   
        })
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/student/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.componentDidMount()     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {    

        const {
            students,
            tuteurs,
            tuteur,
            selectedFile,
            addImport,
            add,
            edit,
            show,
            valuetab,
            matricule,
            first_name,
            second_name,
            genre,
            birthdate,
            birthplace,
            father,
            mother,
            password,
            passwordc,
            showPassword,
            showPasswordc,
            inscriptions,
            routines,
            cover,
            showcover,
            miniature,
            path,
            subjectsYears,
            notes,
            StudentAbsences,
            AccountYears,
            id,
            Payments,
            loading,
            severity,
            message,
            isAlert,
            anchorEl,
            anchorElDate
        } = this.state 

        let CompositionYear = this.state.CompositionYear //.filter( (ele, ind) => ind === this.state.CompositionYear.findIndex(elem => elem.composition_id === ele.composition_id))

        let column = []
        if(this.props.accesses.approve){
            column = [
                { 
                    field: 'image', 
                    headerName: 'Photo',
                    width: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        if(cellValues.row.cover !== null){
                            return(
                                <IconButton  onClick={() => this.setState({id: cellValues.row.id, showcover: true})}> 
                                    <Avatar src={MainUrl() + '/'  + cellValues.row.cover} />
                                </IconButton>
                            )    
                        }else{
                            let a  = cellValues.row.user.first_name
                            let b = cellValues.row.user.second_name
                            return(
                                <IconButton  onClick={() => this.setState({id: cellValues.row.id, showcover: true})}>
                                    <Avatar>{a.charAt(0) + b.charAt(0)}</Avatar>
                                </IconButton>
                            )  
                        }                        
                    }
                },
                { 
                    field: 'matricule', 
                    headerName: 'Matricule',
                    flex: 1/2,
                    renderCell: (cellValues) => { return cellValues.row.user.matricule}
                },
                { 
                    field: 'first_name', 
                    headerName: 'Prénom',
                    flex: 1,
                    renderCell: (cellValues) => { return cellValues.row.user.first_name}
                },
                { 
                    field: 'second_name', 
                    headerName: 'Nom de famille',
                    flex: 1,
                    renderCell: (cellValues) => { return cellValues.row.user.second_name}
                },
                { 
                    field: 'activated', 
                    headerName: 'Statut',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <Tooltip title={cellValues.row.activated ? "Statut actif" : "Statut non actif"}>
                                <IconButton onClick={() => this.delete(cellValues.row.id)} >   
                                    <Switch checked={cellValues.row.activated}/>
                                </IconButton> 
                            </Tooltip>                            
                        )                          
                    }
                },
                { 
                    field: 'actions', 
                    headerName: 'Actions',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <React.Fragment>
                                <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                    <MoreVertIcon style={{color: "#306bff"}} />
                                </IconButton>

                                <Menu                                                            
                                    id={cellValues.row.id}
                                    anchorEl={anchorEl[cellValues.row.id]}
                                    keepMounted
                                    open={Boolean(anchorEl[cellValues.row.id])}
                                    onClose={() => this.setState({anchorEl: []})}    
                                    PaperProps={{
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 0,
                                            ml:1,
                                            width:150
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem  onClick={() => { this.show(cellValues.row.id) }}>
                                        <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                    </MenuItem>

                                    {!this.props.accesses.write || 
                                        <MenuItem onClick={() => { this.edit(cellValues.row.id) }} disabled={!this.props.accesses.write}>
                                            <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                        </MenuItem>
                                    }
                                </Menu>
                            </React.Fragment>
                        )
                    }
                }
            ]
        }else{
            column = [
                { 
                    field: 'image', 
                    headerName: 'Photo',
                    width: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        if(cellValues.row.cover !== null){
                            return(
                                <IconButton  onClick={() => this.setState({id: cellValues.row.id, showcover: true})}>
                                    <Avatar src={MainUrl() + '/'  + cellValues.row.cover} />
                                </IconButton>
                            )    
                        }else{
                            let a  = cellValues.row.user.first_name
                            let b = cellValues.row.user.second_name
                            return(
                                <IconButton  onClick={() => this.setState({id: cellValues.row.id, showcover: true})}>
                                    <Avatar>{a.charAt(0) + b.charAt(0)}</Avatar>
                                </IconButton>
                            )  
                        }                        
                    }
                },
                { 
                    field: 'matricule', 
                    headerName: 'Matricule',
                    flex: 1/2,
                    renderCell: (cellValues) => { return cellValues.row.user.matricule}
                },
                { 
                    field: 'first_name', 
                    headerName: 'Prénom',
                    flex: 1,
                    renderCell: (cellValues) => { return cellValues.row.user.first_name}
                },
                { 
                    field: 'second_name', 
                    headerName: 'Nom de famille',
                    flex: 1,
                    renderCell: (cellValues) => { return cellValues.row.user.second_name}
                },
                { 
                    field: 'actions', 
                    headerName: 'Actions',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <React.Fragment>
                                <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                    <MoreVertIcon style={{color: "#306bff"}} />
                                </IconButton>

                                <Menu                                                            
                                    id={cellValues.row.id}
                                    anchorEl={anchorEl[cellValues.row.id]}
                                    keepMounted
                                    open={Boolean(anchorEl[cellValues.row.id])}
                                    onClose={() => this.setState({anchorEl: []})}    
                                    PaperProps={{
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 0,
                                            ml:1,
                                            width:150
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem  onClick={() => { this.show(cellValues.row.id) }}>
                                        <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                    </MenuItem>

                                    {!this.props.accesses.write || 
                                        <MenuItem onClick={() => { this.edit(cellValues.row.id) }} disabled={!this.props.accesses.write}>
                                            <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                        </MenuItem>
                                    }
                                </Menu>
                            </React.Fragment>
                        )
                    }
                }
            ]
        }

        let DataStudents=students
        if(this.props.search !== ''){
            let result = []
            DataStudents.map((elem, index) => {
                elem.search = elem.user.matricule + ' ' + elem.user.first_name + ' ' + elem.user.second_name + ' ' + elem.sexe
                result = result.concat(elem)
            })
            DataStudents = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }
        if(this.props.accesses.type == 'student'){
            const user_id = JSON.parse(localStorage.getItem("USER")).id
            DataStudents = DataStudents.filter(c => c["user_id"] === user_id)
        }
        if(this.props.accesses.type == 'parent'){
            const user_id = JSON.parse(localStorage.getItem("USER")).id
            const tuteur_id  = tuteurs.filter(c => c["user_id"] === user_id)[0]?.id
            DataStudents = DataStudents.filter(c => c["tuteur_id"] === tuteur_id)
        }        

        const displaydate = (value) => {
            var date = new Date(value);
            if(date.getMonth() == 0){
                return date.getDate() + " janvier " + date.getFullYear()
            }
            if(date.getMonth() == 1){
                return date.getDate() + " Février " + date.getFullYear()
            }
            if(date.getMonth() == 2){
                return date.getDate() + " Mars " + date.getFullYear()
            }
            if(date.getMonth() == 3){
                return date.getDate() + " Avril " + date.getFullYear()
            }
            if(date.getMonth() == 4){
                return date.getDate() + " Mai " + date.getFullYear()
            }
            if(date.getMonth() == 5){
                return date.getDate() + " Juin " + date.getFullYear()
            }
            if(date.getMonth() == 6){
                return date.getDate() + " Juillet " + date.getFullYear()
            }
            if(date.getMonth() == 7){
                return date.getDate() + " Août " + date.getFullYear()
            }
            if(date.getMonth() == 8){
                return date.getDate() + " Septembre " + date.getFullYear()
            }
            if(date.getMonth() == 9){
                return date.getDate() + " Octobre " + date.getFullYear()
            }
            if(date.getMonth() == 10){
                return date.getDate() + " Novembre " + date.getFullYear()
            }
            if(date.getMonth() == 11){
                return date.getDate() + " Décembre " + date.getFullYear()
            }
        }

        const Composition = (classe_id, year_id) => {
            let result = CompositionYear.filter(c => c["activated"] === 1)
            result = result.filter(c => c["classe_id"] === classe_id)
            result = result.filter(c => c["year_id"] === year_id)
            return result
        }

        const subjectsYear = (classe_id, year_id) => {
            let result = subjectsYears
            result = result.filter(c => c["classe_id"] === classe_id)
            result = result.filter(c => c["year_id"] === year_id)
            result = result.filter( (ele, ind) => ind === result.findIndex(elem => elem.subject_id === ele.subject_id))  
            return result
        }

        const note = (classe_id, year_id) => {
            let result = notes
            result = result.filter(c => c["classe_id"] === classe_id)
            result = result.filter(c => c["year_id"] === year_id)
            return result
        }

        const getNoteCl = (student_id, subject_id, composition_id, classe_id, year_id) => {
            let resultat = 0
            let item = note(classe_id, year_id).filter(c => (c["student_id"] === student_id && c["subject_id"] === subject_id && c["composition_id"] === composition_id))[0]
            resultat = item.moyen_classe
            return resultat
        }

        const getNoteCompo = (student_id, subject_id, composition_id, classe_id, year_id) => {
            let resultat = 0
            let item = note(classe_id, year_id).filter(c => (c["student_id"] === student_id && c["subject_id"] === subject_id && c["composition_id"] === composition_id))[0]
            resultat = item.moyen_compo
            return resultat
        }

        const getNoteGen = (student_id, subject_id, composition_id, classe_id, year_id) => {
            return (getNoteCl(student_id, subject_id, composition_id, classe_id, year_id) + getNoteCompo(student_id, subject_id, composition_id, classe_id, year_id))/3
        }

        const getNoteGenCoef = (student_id, subject_id, composition_id, classe_id, year_id) => {
            let resultat = 0
            let coef = 0
            let item = note(classe_id, year_id).filter(c => (c["student_id"] === student_id && c["subject_id"] === subject_id && c["composition_id"] === composition_id))[0]
            coef = item.coef
            resultat = getNoteGen(student_id, subject_id, composition_id, classe_id, year_id) * coef
            return resultat
        }

        const GetTotalCoef= (classe_id, year_id) => {
            let resultat = 0
            subjectsYear(classe_id, year_id).map((element, index) => {
                resultat = resultat + element.coef
            })
            return resultat
        }

        const GetTotalGen = (student_id, composition_id, classe_id, year_id) => {
            let resultat = 0
            subjectsYear(classe_id, year_id).map((subject, index) => {
                resultat = resultat + getNoteGenCoef(student_id, subject.subject_id, composition_id, classe_id, year_id)
            })
            return resultat
        }

        const GetMoyenneGenere = (student_id, composition_id, classe_id, year_id) => { 
            let resultat = 0
            let coef = 0
            subjectsYear(classe_id, year_id).map((subject, index) => {               
                let item = note(classe_id, year_id).filter(c => (c["student_id"] === student_id && c["subject_id"] === subject.subject_id && c["composition_id"] === composition_id))[0]
                coef = coef + item.coef
            })            
            resultat = GetTotalGen(student_id, composition_id, classe_id, year_id)/coef
            return resultat
        }

        const getMoyenneAnnuelle = (student_id, classe_id, year_id) => {
            let resultat = 0
            let i = 0
            Composition(classe_id, year_id).map((element) => {
                i = i + 1
                resultat = resultat + GetMoyenneGenere(student_id, element.composition_id, classe_id, year_id)
            })
            resultat = resultat/i
            return resultat
        }

        const getNotApreciation = (note) => {
            if(note >= 10){return "Passe"}
            else if(note >= 7){return "Rédouble"}
            else{return "Exclu"}
        }

        const GetPaymet = (year_id, classe_id, account_year_id) => {
            let resultat = 0
            let Payment = Payments.filter(c => (c["year_id"] === year_id && c["classe_id"] === classe_id && c["account_year_id"] === account_year_id && c["student_id"] === id))
            Payment.map((element) => {
                resultat = resultat + element.amont 
            })
            return resultat
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    {!this.props.accesses.write ||
                        <Button 
                            variant="contained" 
                            disabled={!this.props.accesses.write} 
                            sx={{bgcolor:'#306bff', mr:2}}
                            onClick={() => this.setState({ addImport: true })}
                        >
                            Importer depuis un fichier csv
                        </Button>
                    }
                    
                    {!this.props.accesses.write ||
                        <Button 
                            variant="contained"
                            disabled={!this.props.accesses.write} 
                            onClick={() => this.setState({add: true})} 
                            sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff'}}
                        >
                            Ajouter un(e) étudiant(e)s
                        </Button>    
                    }
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:620, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={DataStudents}
                            columns={column}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Ligne par page'
                                }
                            }}
                        />
                    </Card>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter un(e) étudiant(e)
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Matricule"
                                        name="matricule"
                                        style={{ width: '100%' }}
                                        value={matricule}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Sexe"
                                        name="genre"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={genre}
                                    >
                                        <MenuItem key="Masculin" value="M">Masculin</MenuItem>
                                        <MenuItem key="Féminin" value="F">Féminin</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Prénom"
                                        name="first_name"
                                        style={{ width: '100%' }}
                                        value={first_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de famille"
                                        name="second_name"
                                        style={{ width: '100%' }}
                                        value={second_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Prénom du père"
                                        name="father"
                                        style={{ width: '100%' }}
                                        value={father}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Prénom et nom de la mère"
                                        name="mother"
                                        style={{ width: '100%' }}
                                        value={mother}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6}>                                    
                                    <TextField
                                        id="outlined-required"
                                        label="Date de naissance"
                                        style={{ width: '100%' }}
                                        value={displaydate(birthdate)}
                                        onClick={this.handleClickDate.bind(this)} 
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Lieu de naissance"
                                        name="birthplace"
                                        style={{ width: '100%' }}
                                        value={birthplace}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="password"
                                        label="Nouveau mot de passe"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPassword:! showPassword})} 
                                                    >
                                                        {showPassword ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="passwordc"
                                        label="Mot de passe confirmation"
                                        type={showPasswordc ? 'text' : 'password'}
                                        value={passwordc}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPasswordc:! showPasswordc})} 
                                                    >
                                                        {showPasswordc ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Éditer un(e) étudiant(e)
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmitEdit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Matricule"
                                        name="matricule"
                                        style={{ width: '100%' }}
                                        value={matricule}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Sexe"
                                        name="genre"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={genre}
                                    >
                                        <MenuItem key="Masculin" value="M">Masculin</MenuItem>
                                        <MenuItem key="Féminin" value="F">Féminin</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Prénom"
                                        name="first_name"
                                        style={{ width: '100%' }}
                                        value={first_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de famille"
                                        name="second_name"
                                        style={{ width: '100%' }}
                                        value={second_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Prénom du père"
                                        name="father"
                                        style={{ width: '100%' }}
                                        value={father}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Prénom et nom de la mère"
                                        name="mother"
                                        style={{ width: '100%' }}
                                        value={mother}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6}>                                    
                                    <TextField
                                        id="outlined-required"
                                        label="Date de naissance"
                                        style={{ width: '100%' }}
                                        value={displaydate(birthdate)}
                                        onClick={this.handleClickDate.bind(this)} 
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Lieu de naissance"
                                        name="birthplace"
                                        style={{ width: '100%' }}
                                        value={birthplace}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="password"
                                        label="Nouveau mot de passe"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPassword:! showPassword})} 
                                                    >
                                                        {showPassword ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="passwordc"
                                        label="Mot de passe confirmation"
                                        type={showPasswordc ? 'text' : 'password'}
                                        value={passwordc}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPasswordc:! showPasswordc})} 
                                                    >
                                                        {showPasswordc ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={addImport}
                    onClose={() => this.setState({addImport: false})}
                    scroll='paper'    
                >
                    <DialogTitle id="scroll-dialog-title" style={{display: 'flex', flexDirection:'row', justifyContent:'space-between',}}>
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Importer liste des Étudiants
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addImport: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </DialogTitle>

                    <DialogContent dividers>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>                             
                            <Grid item xs={12} md={12} style={{textAlign: "center"}}> 
                                <Dropzone onDrop={acceptedFiles  => this.fileDropImport(acceptedFiles)}>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />

                                                {selectedFile.length === 0 ? 
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                        <p>Faites glisser et déposez un fichier ici, ou cliquez pour sélectionner un fichier</p>
                                                    </React.Fragment>
                                                :
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  />
                                                        
                                                        <Typography component="p" variant="p" color="text.secondary" sx={{}}>
                                                            {selectedFile.path}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>                        
                        <Button 
                            variant="contained"
                            fullWidth
                            onClick={this.handleSubmitImport.bind(this)}
                        >
                            Importer le fichier
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={show}
                    onClose={() => this.setState({show: false})}
                    scroll={'paper'}
                >
                    <DialogTitle>{'[' + matricule + ']< < < < < ' + first_name + '< < ' + second_name}</DialogTitle>

                    <DialogContent dividers={true}>
                        <DialogContentText tabIndex={-1} >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TabContext value={valuetab}>
                                        <Box sx={{  borderColor: 'divider'}}>
                                            <TabList onChange={this.handleChangetab.bind(this)} variant="scrollable" scrollButtons="auto">
                                                <Tab label="Données d’identité" value={1} />  

                                                <Tab label="Emplois du temps" value={2} />

                                                <Tab label="Absences" value={3} />

                                                <Tab label="Notes" value={4} />

                                                <Tab label="Frais scolaire / Paiements" value={5} />
                                            </TabList>
                                        </Box>

                                        <TabPanel value={1} sx={{p:2, minHeight:"60vh"}}>
                                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                                <Grid item xs={12} md={12}> 
                                                    <Box sx={{display:"flex",  justifyContent:"flex-start", alignItems: 'center'}}>
                                                        <Box mr={4}>
                                                            {cover !== null ? 
                                                                <Avatar src={MainUrl() + '/'  + cover}  sx={{ width: 180, height: 180 }}  />
                                                            :
                                                                <Avatar sx={{ width: 180, height: 180 }} >
                                                                    <Typography sx={{fontSize:48, fontWeight:700, color:'white' }}>
                                                                        {first_name.charAt(0) + second_name.charAt(0)}
                                                                    </Typography>
                                                                </Avatar>
                                                            }   
                                                        </Box>

                                                        <Box>
                                                            <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Matricule:</strong> {matricule}</Typography>
                                                            <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Prénom:</strong> {first_name}</Typography>
                                                            <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Nom:</strong> {second_name}</Typography>
                                                            <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Sexe:</strong> {genre}</Typography>
                                                            <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Date de naissance:</strong> {displaydate(birthdate)}</Typography>
                                                            <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Lieu de naissance:</strong> {birthplace}</Typography>
                                                            <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Fils/Fille de:</strong> {father}</Typography>
                                                            <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Et de:</strong> {mother}</Typography> 
                                                            <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Inscriptions:</strong> {mother}</Typography> 
                                                        </Box>
                                                    </Box>

                                                    {inscriptions.length > 0 && <Divider sx={{my:2}}>Année académique</Divider> }

                                                    {inscriptions.map((item, index) => (
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}><strong>{item.year.name}:</strong> {item.classe.name} | {item.cycle.name}</Typography> 
                                                    ))}

                                                    {tuteur !== null && <Divider sx={{my:2}}>Compte tuteur associé</Divider>}

                                                    {tuteur !== null && <React.Fragment>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Matricule:</strong> {tuteur.user.matricule}</Typography>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Prénom:</strong> {tuteur.user.first_name}</Typography>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Nom:</strong> {tuteur.user.second_name}</Typography>
                                                    </React.Fragment>}
                                                </Grid>
                                            </Grid>
                                        </TabPanel>

                                        <TabPanel value={2} sx={{p:2, minHeight:"60vh"}}>
                                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    {inscriptions.map((item, index) => (
                                                        <React.Fragment>
                                                            <Divider sx={{my:2}}>Année académique: {item.year.name}</Divider>
                                                            
                                                            <table className="table table-bordered">
                                                                <thead >
                                                                    <tr style={{textAlign: "center"}}>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Lundi</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Mardi</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Mercredi</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Jeudi</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Vendredi</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Samedi</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Dimanche</Typography></th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    <td style={{textAlign: "center"}}>
                                                                        {routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Lundi")).map((routine, index) => (
                                                                            <Box>
                                                                                {routine.start} - {routine.end} <br />
                                                                                {routine.subject.name} <br />  
                                                                                {index +1 < routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Lundi")).length && <Divider sx={{bgcolor:'#306bff'}} />}
                                                                            </Box>
                                                                        ))}                                            
                                                                    </td>

                                                                    <td style={{textAlign: "center"}}>
                                                                        {routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Mardi")).map((routine, index) => (
                                                                            <Box>
                                                                                {routine.start} - {routine.end} <br />
                                                                                {routine.subject.name} <br />  
                                                                                {index +1 < routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Mardi")).length && <Divider sx={{bgcolor:'#306bff'}} />}
                                                                            </Box>
                                                                        ))}                                            
                                                                    </td>

                                                                    <td style={{textAlign: "center"}}>
                                                                        {routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Mercredi")).map((routine, index) => (
                                                                            <Box>
                                                                                {routine.start} - {routine.end} <br />
                                                                                {routine.subject.name} <br />  
                                                                                {index +1 < routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Mercredi")).length && <Divider sx={{bgcolor:'#306bff'}} />}
                                                                            </Box>
                                                                        ))}                                            
                                                                    </td>

                                                                    <td style={{textAlign: "center"}}>
                                                                        {routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Jeudi")).map((routine, index) => (
                                                                            <Box>
                                                                                {routine.start} - {routine.end} <br />
                                                                                {routine.subject.name} <br />  
                                                                                {index +1 < routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Jeudi")).length && <Divider sx={{bgcolor:'#306bff'}} />}
                                                                            </Box>
                                                                        ))}                                            
                                                                    </td>

                                                                    <td style={{textAlign: "center"}}>
                                                                        {routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Vendredi")).map((routine, index) => (
                                                                            <Box>
                                                                                {routine.start} - {routine.end} <br />
                                                                                {routine.subject.name} <br />  
                                                                                {index +1 < routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Vendredi")).length && <Divider sx={{bgcolor:'#306bff'}} />}
                                                                            </Box>
                                                                        ))}                                            
                                                                    </td>

                                                                    <td style={{textAlign: "center"}}>
                                                                        {routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Samedi")).map((routine, index) => (
                                                                            <Box>
                                                                                {routine.start} - {routine.end} <br />
                                                                                {routine.subject.name} <br />  
                                                                                {index +1 < routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Samedi")).length && <Divider sx={{bgcolor:'#306bff'}} />}
                                                                            </Box>
                                                                        ))}                                            
                                                                    </td>

                                                                    <td style={{textAlign: "center"}}>
                                                                        {routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Dimanche")).map((routine, index) => (
                                                                            <Box>
                                                                                {routine.start} - {routine.end} <br />
                                                                                {routine.subject.name} <br />  
                                                                                {index +1 < routines.filter(c => (c["year_id"] === item.year_id && c["day"] === "Dimanche")).length && <Divider sx={{bgcolor:'#306bff'}} />}
                                                                            </Box>
                                                                        ))}                                            
                                                                    </td>
                                                                </tbody>
                                                            </table>
                                                        </React.Fragment>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </TabPanel>

                                        <TabPanel value={3} sx={{p:2, minHeight:"60vh"}}>
                                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    {inscriptions.map((item, index) => (
                                                        <React.Fragment>
                                                            <Divider sx={{my:2}}>Année académique: {item.year.name}</Divider>
                                                            
                                                            <table className="table table-bordered">
                                                                <thead >
                                                                    <tr>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Type d'absences</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Date</Typography></th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {StudentAbsences.filter(c => c["year_id"] === item.year_id).map((elem, index) => (
                                                                        <tr>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{elem.motif}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{new Date(elem.day).toLocaleDateString()}</Typography></td>
                                                                        </tr>                                                                       
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </React.Fragment>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </TabPanel>

                                        <TabPanel value={4} sx={{p:2, minHeight:"60vh"}}>
                                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    {inscriptions.map((item, index) => (
                                                        <React.Fragment>
                                                            <Divider sx={{my:2}}>Année académique: {item.year.name}</Divider>
                                                            
                                                            <table className="table table-bordered">
                                                                <thead >
                                                                    <tr>
                                                                        <th scope="col" colspan="2"> </th>
                                                                        {Composition(item.classe_id, item.year_id).map((element) => (
                                                                            <th scope="col" colspan="4"><Typography color="text.secondary" sx={{fontSize:16 }}>{element.composition.name}</Typography></th>
                                                                        ))}
                                                                    </tr>

                                                                    <tr>
                                                                        <th  scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>DISCIPLINES</Typography></th>
                                                                        <th  scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>COEF</Typography></th>
                                                                        {Composition(item.classe_id, item.year_id).map((element) => (
                                                                            <React.Fragment>
                                                                                <th  scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>N/20</Typography></th>
                                                                                <th  scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>N/40</Typography></th>
                                                                                <th  scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>M/Gén</Typography></th>
                                                                                <th  scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>M/Coéf.</Typography></th>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {subjectsYear(item.classe_id, item.year_id).map((subject, index) => (
                                                                        <tr>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{subject.subject.name}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{subject.coef}</Typography></td>
                                                                            {Composition(item.classe_id, item.year_id).map((composition) => (
                                                                                <React.Fragment>
                                                                                    <td><Typography color="text.secondary" sx={{fontSize:16 }}>{getNoteCl(item.student_id, subject.subject_id, composition.composition_id, item.classe_id, item.year_id)}</Typography></td>
                                                                                    <td><Typography color="text.secondary" sx={{fontSize:16 }}>{getNoteCompo(item.student_id, subject.subject_id, composition.composition_id, item.classe_id, item.year_id)}</Typography></td>
                                                                                    <td><Typography color="text.secondary" sx={{fontSize:16 }}>{getNoteGen(item.student_id, subject.subject_id, composition.composition_id, item.classe_id, item.year_id).toFixed(2)}</Typography></td>
                                                                                    <td><Typography color="text.secondary" sx={{fontSize:16 }}>{getNoteGenCoef(item.student_id, subject.subject_id, composition.composition_id, item.classe_id, item.year_id).toFixed(2)}</Typography></td>
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </tr>
                                                                    ))}

                                                                    <tr>
                                                                        <td><Typography color="text.secondary" sx={{fontSize:16 }}>Total</Typography></td>
                                                                        <td style={{textAlign:'right'}}><Typography color="text.secondary" sx={{fontSize:16 }}>{GetTotalCoef(item.classe_id, item.year_id)}</Typography></td>
                                                                        {Composition(item.classe_id, item.year_id).map((composition) => (
                                                                            <React.Fragment>
                                                                                <td style={{textAlign:'center'}} colspan="3"> </td> 
                                                                                <td style={{textAlign:'right'}} ><Typography color="text.secondary" sx={{fontSize:16 }}>{GetTotalGen(item.student_id, composition.composition_id, item.classe_id, item.year_id).toFixed(2)}</Typography></td> 
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </tr>  

                                                                    <tr>
                                                                        <td colspan="2"><Typography color="text.secondary" sx={{fontSize:16, fontWeight: 700}}>Moyenne</Typography></td>
                                                                        {Composition(item.classe_id, item.year_id).map((composition) => (
                                                                            <React.Fragment>
                                                                                <td style={{textAlign:'center'}} colspan="4"><Typography color="text.secondary" sx={{fontSize:16, fontWeight: 700 }}>{GetMoyenneGenere(item.student_id, composition.composition_id, item.classe_id, item.year_id).toFixed(2)}</Typography></td>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </tr>   

                                                                    <tr>
                                                                        <td colspan="2"><Typography color="text.secondary" sx={{fontSize:16, fontWeight: 700}}>Moyenne Annuelle</Typography></td>
                                                                        <td style={{textAlign:'center' }} colspan={4 * Composition(item.classe_id, item.year_id).length}><Typography color="text.secondary" sx={{fontSize:16, fontWeight: 700}}>{getMoyenneAnnuelle(item.student_id, item.classe_id, item.year_id).toFixed(2)}</Typography></td>
                                                                    </tr>  

                                                                    <tr>
                                                                        <td colspan="2"><Typography color="text.secondary" sx={{fontSize:16, fontWeight: 700}}>Année Scolaire {item.year.name}</Typography></td>
                                                                        <td style={{textAlign:'center' }} colspan={4 * Composition(item.classe_id, item.year_id).length}><Typography color="text.secondary" sx={{fontSize:16, fontWeight: 700}}>{getNotApreciation(getMoyenneAnnuelle(item.student_id, item.classe_id, item.year_id))}</Typography></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </React.Fragment>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </TabPanel>

                                        <TabPanel value={5} sx={{p:2, minHeight:"60vh"}}>
                                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    {inscriptions.map((item, index) => (
                                                        <React.Fragment>
                                                            <Divider sx={{my:2}}>Année académique: {item.year.name}</Divider>

                                                            <table className="table table-bordered">
                                                                <thead >
                                                                    <tr>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Frais scolaire</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Montant</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Payé</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Restant</Typography></th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {AccountYears.filter(c => c["year_id"] === item.year_id).map((elem, index) => (
                                                                        <tr>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{elem.account.name}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{elem.amont.toLocaleString()}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{GetPaymet(item.year_id, item.classe_id, elem.id).toLocaleString()}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{(elem.amont - GetPaymet(item.year_id, item.classe_id, elem.id)).toLocaleString()}</Typography></td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </React.Fragment>
                                                    ))}
                                                </Grid>
                                            </Grid>                                             
                                        </TabPanel>
                                    </TabContext>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button fullWidth onClick={() => this.setState({show: false})}>Fermer</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={showcover}
                    onClose={() => this.setState({showcover: false})}      
                    sx={{'.css-tlc64q-MuiPaper-root-MuiDialog-paper':{bgcolor:'#f4f4f4'}}}                  
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter une photo
                        </Typography>

                        <IconButton onClick={() =>  this.setState({showcover: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmitCover.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12} style={{textAlign: "center"}}> 
                                    <Dropzone onDrop={acceptedFiles  => this.fileDrop(acceptedFiles)}>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    {path === "" ? 
                                                        <React.Fragment>
                                                            <CollectionsIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                            <p>Faites glisser et déposez une photo ici, ou cliquez pour sélectionner la photo</p>
                                                        </React.Fragment>                                                        
                                                    :
                                                        <Box sx={{display:"flex",  justifyContent:"center", alignItems: 'center'}}>
                                                            <Avatar src={path} sx={{ width: 140, height: 140 }} />
                                                        </Box>                                                        
                                                    }
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour enregistrer
                        </Button>
                    </form>
                </Dialog>

                <Menu
                    elevation={12}
                    id="basic-menu"
                    anchorEl={anchorElDate}
                    open={Boolean(anchorElDate)}
                    onClose={() => {this.setState({ anchorElDate: null})}}
                    PaperProps={{
                        sx: {
                            mt:4,
                            ml: '-16px',
                        }
                    }}
                >
                    <Calendar
                        date={birthdate}
                        onChange={this.handleSelectDate.bind(this)}
                        locale={locales['fr']}
                    />
                </Menu>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }
}