import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import SelectClasse from '../SelectClasse'

export default class Payments extends Component {

    constructor(props) {
        super(props)
        this.state = {
            payments: [],
            AccountYears: [],
            students: [],

            add: false,
            account_year_id: null,
            student_id: null,
            classe_id: null,
            amont: 0,

            severity: "",
            isAlert:false,
            loading: true,   

            anchorEl: [],               
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Paiements"
        this.setState({loading: false}) 
    }

    isAlertChange(){this.setState({isAlert:false}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeClasse(data){
        if(data.name === 'classe_id'){
            this.setState({ 
                classe_id: data.classe_id,
                loading: true
            }) 
            this.getData(data.classe_id)
        }        
    }

    getData(id){ 
        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: id,
        }
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/payments', 
            bodyParameters, 
            config
        )
        .then(response => {
            this.setState({ 
                payments: response.data.data,
                AccountYears: response.data.AccountYears,
                students: response.data.students,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({loading: true})   

        let elem = this.state.students.filter(c => c["student_id"] === this.state.student_id)[0]
            
        const bodyParameters = {
            year_id: this.props.year_id,
            account_year_id: this.state.account_year_id,
            classe_id: this.state.classe_id,
            student_id: this.state.student_id,
            user_id: elem.user_id,
            amont: this.state.amont
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/payment',
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.getData(this.state.classe_id)            
            }                       
        }).catch(error => {
            this.setState({
                add: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/payment/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.getData(this.state.classe_id)
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {

        const {
            payments,
            AccountYears,
            students,
            add,
            account_year_id,
            student_id,
            classe_id,
            amont,

            loading,
            severity,
            message,
            isAlert
        } = this.state

        let student = []
        if(classe_id !== null){
            student = students.filter(c => c["classe_id"] === classe_id)
        }

        let AccountYear = AccountYears
        let payment = []
        if(this.props.year_id !== null){
            AccountYear = AccountYear.filter(c => c["year_id"] === this.props.year_id)
            payment = payments.filter(c => c["year_id"] === this.props.year_id)
        }
        if(classe_id !== null){
            AccountYear = AccountYear.filter(c => c["classe_id"] === classe_id)
            payment = payment.filter(c => c["classe_id"] === classe_id)
        }
        if(this.props.search !== ''){
            let result = []
            payment.map((elem, index) => {
                elem.search = elem.user.matricule + ' ' + elem.user.first_name + ' ' + elem.user.second_name
                result = result.concat(elem)
            })
            payment = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }        

        const displayAccount = (account_year_id) => {
            let resultat = ""
            if(AccountYears.filter(c => c["id"] === account_year_id)[0] != undefined){
                resultat = AccountYears.filter(c => c["id"] === account_year_id)[0].account.name
            }
            return resultat
        }      
        
        const getSomm = () => {
            let resultat = 0
            payment.map((answer) => {
                resultat = resultat + answer.amont
            })
            return resultat
        }
        
        return(
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    <SelectClasse 
                        year_id={this.props.year_id} 
                        onChange={this.handleChangeClasse.bind(this)} 
                        width={200} 
                        mr={2}
                    />

                    <TextField
                        select
                        disabled={classe_id === null}
                        label="Étudiant(e)"
                        name="student_id"
                        onChange={this.handleChange.bind(this)}
                        size="small"
                        style={{ width: 200, marginRight: 16 }}
                        value={student_id || ''}
                    >
                        <MenuItem value={null}>Tous les étudiants</MenuItem>

                        {student.map((option) => (
                            <MenuItem key={option.id} value={option.student_id}>
                                {option.user.matricule} | {option.user.first_name} | {option.user.second_name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Button 
                        variant="contained" 
                        disabled={!this.props.accesses.write || student_id === null} 
                        onClick={() => this.setState({add: true})} 
                        sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff'}}
                    >
                        Effectuer un paiement
                    </Button>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={0}  sx={{ minHeight:570, backgroundColor:'#ffffff', borderRadius:2}}>
                        <div className="row" style={{width: "100%"}}>
                            <div className="col-12 text-center" style={{marginTop:24}}><h5>Journal de caisse</h5></div>

                            <div className="col-12">
                                <table className="table table-hover" style={{width:"100%", textAlign:'left'}}>
                                    <thead>
                                        <tr>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Date de l'opération</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Matricule</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Nom complet</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Classe</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Année</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Type de frais</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Montant Payé</Typography></th>                                                    
                                            <th scope="col" style={{textAlign:'center'}} ><Typography color="text.secondary" sx={{fontSize:16 }}>Actions</Typography></th>
                                        </tr>
                                    </thead>

                                    <tbody>  
                                        {payment.map((answer) => (
                                            <tr>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{new Date(answer.created_at).toLocaleString("lookup")}</Typography></td>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{answer.user.matricule}</Typography></td>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{answer.user.first_name + ' ' + answer.user.second_name}</Typography></td>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{answer.classe.name}</Typography></td>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{answer.year.name}</Typography></td>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{displayAccount(answer.account_year_id)}</Typography></td>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{answer.amont.toLocaleString()}</Typography></td>
                                                <td style={{textAlign:'center'}}>
                                                    <Button
                                                        onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(answer.id)}}}
                                                        variant="contained"
                                                        sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'red', color:'white'}}}
                                                        disabled={!this.props.accesses.remove}
                                                    >
                                                        Supprimer
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))} 

                                        <tr>
                                            <td colspan="6" style={{fontWeight: 600}}>Total</td>
                                            <td colspan="2" style={{fontWeight: 600}}>{getSomm().toLocaleString()}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>   
                    </Card>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Effectuer un paiement
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        select
                                        required
                                        label="Type de frais"
                                        name="account_year_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={account_year_id}
                                    >
                                        {AccountYear.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.account.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Montant"
                                        name="amont"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={amont}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        disabled
                                        select
                                        label="Matricule"
                                        name="student_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={student_id}
                                    >
                                        {student.map((option) => (
                                            <MenuItem key={option.id} value={option.student_id}>
                                                {option.user.matricule} | {option.user.first_name} | {option.user.second_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>                               

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        disabled
                                        label="Classe"
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={student_id}
                                    >
                                        {student.map((option) => (
                                            <MenuItem key={option.id} value={option.student_id}>{option.classe.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        disabled
                                        label="Année académique"
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={student_id}
                                    >
                                        {student.map((option) => (
                                            <MenuItem key={option.id} value={option.student_id}>{option.year.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour effectuer
                        </Button>   
                    </form>

                </Dialog>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}