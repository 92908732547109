import React, { Component } from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import SelectClasse from '../SelectClasse'
import ReactToPrint from 'react-to-print';
import IconButton from '@mui/material/IconButton';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import {MainUrl, SchoolName } from '../../settings'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import Menu from '@mui/material/Menu';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';

export default class Absences extends Component {    

    constructor(props) {
        super(props)
        this.state = {
            absences: [],
            students: [],

            add: false,
            edit: false,
            id: null,
            classe_id: null,
            student_id: null,
            motif: 'Retard',
            day: new Date(),

            anchorElDate: null,
            anchorEl: [],   
            loading: true,
            severity: "",
            message: "",
            isAlert:false
        }
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    componentDidMount(){
        document.title = SchoolName() + " | Absences"
        this.setState({loading: false}) 
    }

    handleChangeClasse(data){
        if(data.name === 'classe_id'){
            this.setState({ 
                classe_id: data.classe_id,
                loading: true
            }) 
            this.getData(data.classe_id)
        }        
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    } 

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleClickDate(event){this.setState({anchorElDate: event.currentTarget})}

    handleSelectDate(date){ this.setState({day: date, anchorElDate: null}) }

    getData(id){ 
        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: id,
        }
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/absences', 
            bodyParameters, 
            config
        )
        .then(response => {
            console.log('reee', response.data)
            this.setState({ 
                absences: response.data.data,
                students: response.data.students,
                loading: false,
            });
        }).catch(error => {
            console.log('error', error.response.data)
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({loading: true})   

        let elem = this.state.students.filter(c => c["student_id"] === this.state.student_id)[0]

        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: this.state.classe_id,
            student_id: this.state.student_id,
            user_id: elem.user_id,
            motif: this.state.motif,
            day: this.state.day,
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/absence',
            bodyParameters,
            config
        ).then(response => {
            console.log("dddd", response.data)
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.getData(this.state.classe_id)            
            }                       
        }).catch(error => {
            console.log("dddd", error.data)
            this.setState({
                add: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    handleSubmitEdit(event){
        event.preventDefault()        
        this.setState({loading: true})   
                
        let elem = this.state.students.filter(c => c["student_id"] === this.state.student_id)[0]

        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: this.state.classe_id,
            student_id: this.state.student_id,
            user_id: elem.user_id,
            motif: this.state.motif,
            day: this.state.day,
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        axios.put(MainUrl() + '/api/v1/absence/' + this.state.id,
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.getData(this.state.classe_id)          
            }                       
        }).catch(error => {
            this.setState({
                edit: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    edit(id){
        const item = this.state.absences.filter(c => c["id"] === id)[0]

        this.setState({
            id: id,
            user_id: item.user_id,
            student_id: item.student_id,
            motif: item.motif,
            day: new Date(item.day),
            edit: true,
            anchorEl: [],   
        })
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/absence/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.getData(this.state.classe_id)     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {   

        const {
            absences,
            students,
            add,
            edit,
            classe_id,
            student_id,
            motif,
            day,
            anchorElDate,
            anchorEl,
            isAlert,
            severity,
            message,
            loading
        } = this.state

        const column = [
            { 
                field: 'matricule', 
                headerName: 'Matricule',
                flex: 1/2,
                renderCell: (cellValues) => { return cellValues.row.user.matricule}
            },
            { 
                field: 'first_name', 
                headerName: 'Prénom',
                flex: 1/2,
                renderCell: (cellValues) => { return cellValues.row.user.first_name}
            },
            { 
                field: 'second_name', 
                headerName: 'Nom de famille',
                flex: 1/2,
                renderCell: (cellValues) => { return cellValues.row.user.second_name}
            },
            { 
                field: 'motif', 
                headerName: "Type d'absences",
                flex: 1/2,
            },
            { 
                field: 'day', 
                headerName: 'Date',
                flex: 1/2,
                renderCell: (cellValues) => { return new Date(cellValues.row.day).toLocaleDateString() }
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                        width:150
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                {!this.props.accesses.write ||
                                    <MenuItem onClick={() => { this.edit(cellValues.row.id) }}>
                                        <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                    </MenuItem>
                                }

                                {!this.props.accesses.remove ||
                                    <MenuItem 
                                        onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(cellValues.row.id)}}} 
                                        sx={{color:'red'}}
                                    >
                                        <ListItemIcon><DeleteIcon fontSize="small" sx={{color:'red'}} /></ListItemIcon>Supprimer
                                    </MenuItem>
                                }
                            </Menu>
                        </React.Fragment>
                    )
                }
            }
        ]

        let DataAbsences = []
        let student = []      
        if(this.props.year_id !== null){
            DataAbsences = absences.filter(c => c["year_id"] === this.props.year_id)            
        }
        if(classe_id !== null){
            student = students.filter(c => c["classe_id"] === classe_id)
            DataAbsences = DataAbsences.filter(c => c["classe_id"] === classe_id)
        }

        if(this.props.search !== ''){
            let result = []
            DataAbsences.map((elem, index) => {
                elem.search = elem.user.matricule + ' ' + elem.user.first_name + ' ' + elem.user.second_name
                result = result.concat(elem)
            })
            DataAbsences = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        const displaydate = (value) => {
            var date = new Date(value);
            if(date.getMonth() == 0){
                return date.getDate() + " janvier " + date.getFullYear()
            }
            if(date.getMonth() == 1){
                return date.getDate() + " Février " + date.getFullYear()
            }
            if(date.getMonth() == 2){
                return date.getDate() + " Mars " + date.getFullYear()
            }
            if(date.getMonth() == 3){
                return date.getDate() + " Avril " + date.getFullYear()
            }
            if(date.getMonth() == 4){
                return date.getDate() + " Mai " + date.getFullYear()
            }
            if(date.getMonth() == 5){
                return date.getDate() + " Juin " + date.getFullYear()
            }
            if(date.getMonth() == 6){
                return date.getDate() + " Juillet " + date.getFullYear()
            }
            if(date.getMonth() == 7){
                return date.getDate() + " Août " + date.getFullYear()
            }
            if(date.getMonth() == 8){
                return date.getDate() + " Septembre " + date.getFullYear()
            }
            if(date.getMonth() == 9){
                return date.getDate() + " Octobre " + date.getFullYear()
            }
            if(date.getMonth() == 10){
                return date.getDate() + " Novembre " + date.getFullYear()
            }
            if(date.getMonth() == 11){
                return date.getDate() + " Décembre " + date.getFullYear()
            }
        }

        return (
            <Grid container spacing={3}>   
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end", alignItems: 'center'}}>
                    <SelectClasse 
                        year_id={this.props.year_id} 
                        onChange={this.handleChangeClasse.bind(this)} 
                        width={200} 
                        mr={2} 
                    />

                    {!this.props.accesses.write ||
                        <Button 
                            variant="contained" 
                            disabled={classe_id === null} 
                            onClick={() => this.setState({add: true})} 
                            sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff', marginRight:1}}
                        >
                            Ajouter une absence
                        </Button>
                    }

                    <ReactToPrint
                        trigger={() => <IconButton disabled={classe_id == null} size="large"><LocalPrintshopIcon fontSize="inherit" /></IconButton>}
                        content={() => this.componentRef}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:620, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={DataAbsences}
                            columns={column}
                            loading={loading}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Ligne par page'
                                }
                            }}
                        />
                    </Card>
                </Grid> 

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter une absence
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        select
                                        label="Étudiant"
                                        name="student_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={student_id}
                                    >
                                        {student.map((option) => (
                                            <MenuItem key={option.id} value={option.student_id}>
                                                {option.user.matricule} | {option.user.first_name} | {option.user.second_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Motif"
                                        name="motif"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={motif || ''}
                                    >
                                        <MenuItem key='Retard' value="Retard">Retard</MenuItem>
                                        <MenuItem key='Absence' value="Absence">Absence</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>                                    
                                    <TextField
                                        id="outlined-required"
                                        label="Date"
                                        style={{ width: '100%' }}
                                        value={displaydate(day)}
                                        onClick={this.handleClickDate.bind(this)} 
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Éditer une absence
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmitEdit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        select
                                        label="Étudiant"
                                        name="student_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={student_id}
                                    >
                                        {student.map((option) => (
                                            <MenuItem key={option.id} value={option.student_id}>
                                                {option.user.matricule} | {option.user.first_name} | {option.user.second_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Motif"
                                        name="motif"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={motif || ''}
                                    >
                                        <MenuItem key='Retard' value="Retard">Retard</MenuItem>
                                        <MenuItem key='Absence' value="Absence">Absence</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>                                    
                                    <TextField
                                        id="outlined-required"
                                        label="Date"
                                        style={{ width: '100%' }}
                                        value={displaydate(day)}
                                        onClick={this.handleClickDate.bind(this)} 
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>

                {DataAbsences.length > 0 && <div  style={{ display: "none", width:"100%" }}> 
                    <div ref={el => (this.componentRef = el)}>
                        <Grid container spacing={3} p={2}>                            
                            <Grid item xs={12}>
                                <div style={{width:'100%', textAlign:'center'}}>
                                    <Typography variant="h5" component="div">Liste d'absences</Typography>

                                    <Divider sx={{my:1}}>Classe: {DataAbsences[0].classe.name} | Année académique: {DataAbsences[0].year.name}</Divider>
                                </div>

                                <table className="table table-hover" style={{width:"100%", textAlign:'left', marginTop:12}}>
                                    <thead> 
                                        <tr>
                                            <th style={{border: '1px solid black', borderCollapse:'collapse' }}>Matricule</th>
                                            <th style={{border: '1px solid black', borderCollapse:'collapse' }}>Prénom</th>
                                            <th style={{border: '1px solid black', borderCollapse:'collapse' }}>Nom de famille</th>
                                            <th style={{border: '1px solid black', borderCollapse:'collapse' }}>Type d'absences</th>
                                            <th style={{border: '1px solid black', borderCollapse:'collapse' }}>Date</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {DataAbsences.map((item, index) => (
                                            <tr>
                                                <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{item.user.matricule}</td>
                                                <td style={{border: '1px solid black', borderCollapse:'collapse'}}>{item.user.first_name}</td>
                                                <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{item.user.second_name}</td>
                                                <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{item.motif}</td>
                                                <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{new Date(item.day).toLocaleDateString()}</td>
                                            </tr> 
                                        ))}
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    </div>
                </div>}

                <Menu
                    elevation={12}
                    id="basic-menu"
                    anchorEl={anchorElDate}
                    open={Boolean(anchorElDate)}
                    onClose={() => {this.setState({ anchorElDate: null})}}
                    PaperProps={{
                        sx: {
                            mt:4,
                            ml: '-16px',
                        }
                    }}
                >
                    <Calendar
                        date={day}
                        onChange={this.handleSelectDate.bind(this)}
                        locale={locales['fr']}
                    />
                </Menu>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>   
        )

    }
}