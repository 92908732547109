import React, { Component } from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import SelectClasse from '../SelectClasse'
import ReactToPrint from 'react-to-print';
import IconButton from '@mui/material/IconButton';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import {MainUrl, SchoolName } from '../../settings'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import Menu from '@mui/material/Menu';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';

export default class Expenses extends Component {

    constructor(props) {
        super(props)
        this.state = {
            expenses: [],

            add: false,
            edit: false,
            id: null,
            pu: 0,
            qte: 0,
            designation: '',

            severity: "",
            message: "",
            isAlert:false,
            loading: true,   

            anchorEl: [],
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Dépenses"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/expenses', config)
        .then(response => {
            this.setState({ 
                expenses: response.data.data,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){this.setState({isAlert:false}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({loading: true})  
            
        const bodyParameters = {
            year_id: this.props.year_id,
            pu: this.state.pu,
            qte: this.state.qte,
            designation: this.state.designation,
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/expense',
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()            
            }                       
        }).catch(error => {
            this.setState({
                add: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    handleSubmitEdit(event){
        event.preventDefault()        
        this.setState({loading: true})   

        const bodyParameters = {
            year_id: this.props.year_id,
            pu: this.state.pu,
            qte: this.state.qte,
            designation: this.state.designation,
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        axios.put(MainUrl() + '/api/v1/expense/' + this.state.id,
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()          
            }                       
        }).catch(error => {
            this.setState({
                edit: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    edit(id){
        const item = this.state.expenses.filter(c => c["id"] === id)[0]

        this.setState({
            id: id,
            pu: item.pu,
            qte: item.qte,
            designation: item.designation,
            edit: true,
            anchorEl: [],   
        })
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/expense/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            })
            this.componentDidMount()     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {    

        const {
            expenses,
            add,
            edit,
            designation,
            qte,
            pu,
            anchorEl,
            loading,
            severity,
            message,
            isAlert
        } = this.state

        const column = [
            { 
                field: 'date', 
                headerName: "Date de l'opération",
                flex: 1/2,
                renderCell: (cellValues) => { return new Date(cellValues.row.created_at).toLocaleString("lookup")}
            },
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 1
            },
            { 
                field: 'qte', 
                headerName: 'Quantité',
                maxWidth: 100,
                renderCell: (cellValues) => { return cellValues.row.qte.toLocaleString()}
            },
            { 
                field: 'pu', 
                headerName: 'P.U',
                maxWidth: 100,
                renderCell: (cellValues) => { return cellValues.row.pu.toLocaleString()}
            },
            { 
                field: 'montant', 
                headerName: "Montant",
                maxWidth: 100,
                renderCell: (cellValues) => { return (cellValues.row.qte * cellValues.row.pu).toLocaleString()}
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                        width:150
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                {!this.props.accesses.write ||
                                    <MenuItem onClick={() => { this.edit(cellValues.row.id) }}>
                                        <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                    </MenuItem>
                                }

                                {!this.props.accesses.remove ||
                                    <MenuItem 
                                        onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(cellValues.row.id)}}} 
                                        sx={{color:'red'}}
                                    >
                                        <ListItemIcon><DeleteIcon fontSize="small" sx={{color:'red'}} /></ListItemIcon>Supprimer
                                    </MenuItem>
                                }
                            </Menu>
                        </React.Fragment>
                    )
                }
            }
        ]

        let expense = []
        if(this.props.year_id !== null){
            expense = expenses.filter(c => c["year_id"] === this.props.year_id)
        }
        if(this.props.search !== ''){
            let result = []
            expense.map((elem, index) => {
                elem.search = elem.designation + ' ' + elem.qte + ' ' + elem.pu
                result = result.concat(elem)
            })
            expense = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        } 

        const getSomm = () => {
            let resultat = 0
            expense.map((answer) => {
                resultat = resultat + (answer.qte * answer.pu)
            })
            return resultat
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end", alignItems: 'center'}}> 
                    {!this.props.accesses.write ||
                        <Button 
                            variant="contained"
                            onClick={() => this.setState({add: true})} 
                            sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff', marginRight:1}}
                        >
                            Ajouter une dépense
                        </Button>
                    }

                    <ReactToPrint
                        trigger={() => <IconButton size="large"><LocalPrintshopIcon fontSize="inherit" /></IconButton>}
                        content={() => this.componentRef}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:520, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={expense}
                            columns={column}
                            loading={loading}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Ligne par page'
                                }
                            }}
                        />
                    </Card>
                </Grid> 

                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end", alignItems: 'center'}}> 
                    <TextField
                        disabled
                        label="Total"
                        style={{ width: 200}}
                        value={getSomm().toLocaleString()}
                        size="small"
                    />
                </Grid>

                <div  style={{ display: "none", width:"100%" }}> 
                    <div ref={el => (this.componentRef = el)}>
                        <Grid container spacing={3} p={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Card elevation={0}  sx={{ minHeight:570, backgroundColor:'#ffffff', borderRadius:2}}>
                                    <div className="row" style={{width: "100%"}}>
                                        <div className="col-12 text-center" style={{marginTop:24}}><h5>Journal de caisse</h5></div>

                                        <div className="col-12">
                                            <table className="table table-hover" style={{width:"100%", textAlign:'left'}}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Date de l'opération</Typography></th>
                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Désignation</Typography></th>
                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Quantité</Typography></th>
                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Prix Unitaire</Typography></th>
                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Montant</Typography></th>                                               
                                                    </tr>
                                                </thead>

                                                <tbody>  
                                                    {expense.map((answer) => (
                                                        <tr>
                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{new Date(answer.created_at).toLocaleString("lookup")}</Typography></td>
                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{answer.designation}</Typography></td>
                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{answer.qte.toLocaleString()}</Typography></td>
                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{answer.pu.toLocaleString()}</Typography></td>
                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{(answer.qte * answer.pu).toLocaleString()}</Typography></td>
                                                        </tr>
                                                    ))} 

                                                    <tr>
                                                        <td colspan="4" style={{fontWeight: 600}}>Total</td>
                                                        <td colspan="1" style={{fontWeight: 600}}>{getSomm().toLocaleString()}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>   
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter une dépense
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Désignation"
                                        name="designation"
                                        style={{ width: '100%' }}
                                        value={designation}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Quantité"
                                        name="qte"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={qte}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Prix unitaire"
                                        name="pu"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={pu}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Éditer une dépense
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmitEdit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Désignation"
                                        name="designation"
                                        style={{ width: '100%' }}
                                        value={designation}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Quantité"
                                        name="qte"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={qte}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Prix unitaire"
                                        name="pu"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={pu}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}