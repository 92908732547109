import React, { Component } from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import SelectClasse from '../SelectClasse'
import ReactToPrint from 'react-to-print';
import IconButton from '@mui/material/IconButton';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import {MainUrl, SchoolName } from '../../settings'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import Menu from '@mui/material/Menu';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';

export default class Routines extends Component {  

    constructor(props) {
        super(props)
        this.state = {
            routines: [],
            subjectYears: [],

            add: false,
            edit: false,
            id: null,
            classe_id: null,
            subject_id: null,
            start: '08:00',
            end: '10:00',
            day: 'Lundi',

            anchorElStart: null,
            anchorElEnd: null,
            loading: true,
            severity: "",
            message: "",
            isAlert:false
        }
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    componentDidMount(){
        document.title = SchoolName() + " | Emplois du temps"
        this.setState({loading: false}) 
    }

    handleChangeClasse(data){
        if(data.name === 'classe_id'){
            this.setState({ 
                classe_id: data.classe_id,
                loading: true
            }) 
            this.getData(data.classe_id)
        }        
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    } 

    handleClickStart(event){this.setState({anchorElStart: event.currentTarget})}

    handleClickEnd(event){this.setState({anchorElEnd: event.currentTarget})}

    handleSelectStart(date){ this.setState({start: date, }) }

    handleSelectEnd(date){ this.setState({end: date, }) }

    getData(id){ 
        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: id,
        }
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/routines', 
            bodyParameters, 
            config
        )
        .then(response => {
            console.log('reee', response.data)
            this.setState({ 
                routines: response.data.data,
                subjectYears: response.data.subjectYears,
                loading: false,
            });
        }).catch(error => {
            console.log('error', error)
            this.setState({
                loading: false,
                severity: 'error',
                //message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({loading: true})   

        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: this.state.classe_id,
            subject_id: this.state.subject_id,
            start: this.state.start,
            end: this.state.end,
            day: this.state.day,
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/routine',
            bodyParameters,
            config
        ).then(response => {
            console.log("dddd", response.data)
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.getData(this.state.classe_id)            
            }                       
        }).catch(error => {
            console.log("dddd", error.data)
            this.setState({
                add: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    handleSubmitEdit(event){
        event.preventDefault()        
        this.setState({loading: true})   
                
        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: this.state.classe_id,
            subject_id: this.state.subject_id,
            start: this.state.start,
            end: this.state.end,
            day: this.state.day,
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        axios.put(MainUrl() + '/api/v1/routine/' + this.state.id,
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.getData(this.state.classe_id)          
            }                       
        }).catch(error => {
            this.setState({
                edit: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    edit(id){
        const item = this.state.routines.filter(c => c["id"] === id)[0]

        this.setState({
            id: id,
            subject_id: item.subject_id,
            start: item.start,
            end: item.end,
            day: item.day,
            edit: true,
        })
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/routine/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.getData(this.state.classe_id)     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {   

        const {
            routines,
            subjectYears,
            add,
            edit,
            classe_id,
            subject_id,
            start,
            end,
            day,
            anchorElStart,
            anchorElEnd,
            anchorEl,
            isAlert,
            severity,
            message,
            loading
        } = this.state

        let routine = []
        let subjectYear = []
        if(this.props.year_id !== null){
            routine = routines.filter(c => c["year_id"] === this.props.year_id)     
            subjectYear = subjectYears.filter(c => c["year_id"] === this.props.year_id)            
        } 
        if(classe_id !== null){
            routine = routine.filter(c => c["classe_id"] === classe_id)
            subjectYear = subjectYear.filter(c => c["classe_id"] === classe_id)
            subjectYear = subjectYear.filter( (ele, ind) => ind === subjectYear.findIndex(elem => elem.subject_id === ele.subject_id))    
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end", alignItems: 'center'}}>
                    <SelectClasse 
                        year_id={this.props.year_id} 
                        onChange={this.handleChangeClasse.bind(this)} 
                        width={200} 
                        mr={2} 
                    />

                    <Button 
                        variant="contained" 
                        disabled={!this.props.accesses.write || classe_id === null} 
                        onClick={() => this.setState({add: true})} 
                        sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff', marginRight:1}}
                    >
                        Ajouter un emploi du temps
                    </Button>

                    <ReactToPrint
                        trigger={() => <IconButton disabled={classe_id == null} size="large"><LocalPrintshopIcon fontSize="inherit" /></IconButton>}
                        content={() => this.componentRef}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{backgroundColor:'#ffffff', borderRadius:2}}>
                        <table className="table table-bordered">
                            <thead >
                                <tr style={{textAlign: "center"}}>
                                    <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Lundi</Typography></th>
                                    <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Mardi</Typography></th>
                                    <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Mercredi</Typography></th>
                                    <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Jeudi</Typography></th>
                                    <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Vendredi</Typography></th>
                                    <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Samedi</Typography></th>
                                    <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Dimanche</Typography></th>
                                </tr>
                            </thead>

                            <tbody>
                                <td style={{textAlign: "center"}}>
                                    {routine.filter(c => c["day"] === "Lundi").map((item) => {
                                        return(
                                            <Box>
                                                <p>
                                                    {item.start} - {item.end} <br />
                                                    {item.subject.name} <br />
                                                        {this.props.accesses.write &&
                                                            <Tooltip title="Modifier">
                                                                <IconButton onClick={() => { this.edit(item.id) }} sx={{borderRadius:2, bgcolor:"#306bff", mr:1 }}> 
                                                                    <ModeIcon sx={{fontSize:16, color:"white"}} /> 
                                                                </IconButton>    
                                                            </Tooltip>
                                                        }

                                                        {this.props.accesses.remove &&   
                                                            <Tooltip title="Supprimer">
                                                                <IconButton onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(item.id)}}} sx={{borderRadius:2, bgcolor:"red"}}> 
                                                                    <DeleteIcon sx={{fontSize:16, color:"white"}} /> 
                                                                </IconButton>    
                                                            </Tooltip>   
                                                        }
                                                </p>
                                                <Divider sx={{bgcolor:'#306bff'}} /> 
                                            </Box>
                                        )
                                    })}
                                </td>

                                <td style={{textAlign: "center"}}>
                                    {routine.filter(c => c["day"] === "Mardi").map((item) => {
                                        return(
                                            <Box>
                                                <p>
                                                    {item.start} - {item.end} <br />
                                                    {item.subject.name} <br />                                                        
                                                    {this.props.accesses.write &&
                                                        <Tooltip title="Modifier">
                                                            <IconButton onClick={() => { this.edit(item.id) }} sx={{borderRadius:2, bgcolor:"#306bff", mr:1 }}> 
                                                                <ModeIcon sx={{fontSize:16, color:"white"}} /> 
                                                            </IconButton>    
                                                        </Tooltip>
                                                    }

                                                    {this.props.accesses.remove &&   
                                                        <Tooltip title="Supprimer">
                                                            <IconButton onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(item.id)}}} sx={{borderRadius:2, bgcolor:"red"}}> 
                                                                <DeleteIcon sx={{fontSize:16, color:"white"}} /> 
                                                            </IconButton>    
                                                        </Tooltip>   
                                                    }                                                      
                                                </p>
                                                <Divider sx={{bgcolor:'#306bff'}} /> 
                                            </Box>
                                        )
                                    })}
                                </td>

                                <td style={{textAlign: "center"}}>
                                    {routine.filter(c => c["day"] === "Mercredi").map((item) => {
                                        return(
                                            <Box>
                                                <p>
                                                    {item.start} - {item.end} <br />
                                                    {item.subject.name} <br />                                                        
                                                    {this.props.accesses.write &&
                                                        <Tooltip title="Modifier">
                                                            <IconButton onClick={() => { this.edit(item.id) }} sx={{borderRadius:2, bgcolor:"#306bff", mr:1 }}> 
                                                                <ModeIcon sx={{fontSize:16, color:"white"}} /> 
                                                            </IconButton>    
                                                        </Tooltip>
                                                    }

                                                    {this.props.accesses.remove &&   
                                                        <Tooltip title="Supprimer">
                                                            <IconButton onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(item.id)}}} sx={{borderRadius:2, bgcolor:"red"}}> 
                                                                <DeleteIcon sx={{fontSize:16, color:"white"}} /> 
                                                            </IconButton>    
                                                        </Tooltip>   
                                                    }                                                      
                                                </p>
                                                <Divider sx={{bgcolor:'#306bff'}} /> 
                                            </Box>
                                        )
                                    })}
                                </td>

                                <td style={{textAlign: "center"}}>
                                    {routine.filter(c => c["day"] === "Jeudi").map((item) => {
                                        return(
                                            <Box>
                                                <p>
                                                    {item.start} - {item.end} <br />
                                                    {item.subject.name} <br />                                                        
                                                    {this.props.accesses.write &&
                                                        <Tooltip title="Modifier">
                                                            <IconButton onClick={() => { this.edit(item.id) }} sx={{borderRadius:2, bgcolor:"#306bff", mr:1 }}> 
                                                                <ModeIcon sx={{fontSize:16, color:"white"}} /> 
                                                            </IconButton>    
                                                        </Tooltip>
                                                    }

                                                    {this.props.accesses.remove &&   
                                                        <Tooltip title="Supprimer">
                                                            <IconButton onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(item.id)}}} sx={{borderRadius:2, bgcolor:"red"}}> 
                                                                <DeleteIcon sx={{fontSize:16, color:"white"}} /> 
                                                            </IconButton>    
                                                        </Tooltip>   
                                                    }                                                      
                                                </p>
                                                <Divider sx={{bgcolor:'#306bff'}} /> 
                                            </Box>
                                        )
                                    })}
                                </td>

                                <td style={{textAlign: "center"}}>
                                    {routine.filter(c => c["day"] === "Vendredi").map((item) => {
                                        return(
                                            <Box>
                                                <p>
                                                    {item.start} - {item.end} <br />
                                                    {item.subject.name} <br />                                                        
                                                    {this.props.accesses.write &&
                                                        <Tooltip title="Modifier">
                                                            <IconButton onClick={() => { this.edit(item.id) }} sx={{borderRadius:2, bgcolor:"#306bff", mr:1 }}> 
                                                                <ModeIcon sx={{fontSize:16, color:"white"}} /> 
                                                            </IconButton>    
                                                        </Tooltip>
                                                    }

                                                    {this.props.accesses.remove &&   
                                                        <Tooltip title="Supprimer">
                                                            <IconButton onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(item.id)}}} sx={{borderRadius:2, bgcolor:"red"}}> 
                                                                <DeleteIcon sx={{fontSize:16, color:"white"}} /> 
                                                            </IconButton>    
                                                        </Tooltip>   
                                                    }                                                      
                                                </p>
                                                <Divider sx={{bgcolor:'#306bff'}} /> 
                                            </Box>
                                        )
                                    })}
                                </td>

                                <td style={{textAlign: "center"}}>
                                    {routine.filter(c => c["day"] === "Samedi").map((item) => {
                                        return(
                                            <Box>
                                                <p>
                                                    {item.start} - {item.end} <br />
                                                    {item.subject.name} <br />                                                        
                                                    {this.props.accesses.write &&
                                                        <Tooltip title="Modifier">
                                                            <IconButton onClick={() => { this.edit(item.id) }} sx={{borderRadius:2, bgcolor:"#306bff", mr:1 }}> 
                                                                <ModeIcon sx={{fontSize:16, color:"white"}} /> 
                                                            </IconButton>    
                                                        </Tooltip>
                                                    }

                                                    {this.props.accesses.remove &&   
                                                        <Tooltip title="Supprimer">
                                                            <IconButton onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(item.id)}}} sx={{borderRadius:2, bgcolor:"red"}}> 
                                                                <DeleteIcon sx={{fontSize:16, color:"white"}} /> 
                                                            </IconButton>    
                                                        </Tooltip>   
                                                    }                                                      
                                                </p>
                                                <Divider sx={{bgcolor:'#306bff'}} /> 
                                            </Box>
                                        )
                                    })}
                                </td>

                                <td style={{textAlign: "center"}}>
                                    {routine.filter(c => c["day"] === "Dimanche").map((item) => {
                                        return(
                                            <Box>
                                                <p>
                                                    {item.start} - {item.end} <br />
                                                    {item.subject.name} <br />                                                        
                                                    {this.props.accesses.write &&
                                                        <Tooltip title="Modifier">
                                                            <IconButton onClick={() => { this.edit(item.id) }} sx={{borderRadius:2, bgcolor:"#306bff", mr:1 }}> 
                                                                <ModeIcon sx={{fontSize:16, color:"white"}} /> 
                                                            </IconButton>    
                                                        </Tooltip>
                                                    }

                                                    {this.props.accesses.remove &&   
                                                        <Tooltip title="Supprimer">
                                                            <IconButton onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(item.id)}}} sx={{borderRadius:2, bgcolor:"red"}}> 
                                                                <DeleteIcon sx={{fontSize:16, color:"white"}} /> 
                                                            </IconButton>    
                                                        </Tooltip>   
                                                    }                                                      
                                                </p>
                                                <Divider sx={{bgcolor:'#306bff'}} /> 
                                            </Box>
                                        )
                                    })}
                                </td>
                            </tbody>
                        </table>
                    </Card>
                </Grid>

                {routine.length > 0 && <div  style={{ display: "none", width:"100%" }}> 
                    <div ref={el => (this.componentRef = el)}>
                        <Grid container spacing={3} p={2}>                            
                            <Grid item xs={12}>
                                <div style={{width:'100%', textAlign:'center'}}>
                                    <Typography variant="h5" component="div">Emplois du temps</Typography>

                                    <Divider sx={{my:1}}>Classe: {routine[0].classe.name} | Année académique: {routine[0].year.name}</Divider>
                                </div>

                                <table className="table table-hover" style={{width:"100%", textAlign:'left', marginTop:12}}>
                                    <thead >
                                        <tr style={{textAlign: "center"}}>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Lundi</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Mardi</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Mercredi</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Jeudi</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Vendredi</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Samedi</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Dimanche</Typography></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <td style={{textAlign: "center"}}>
                                            {routine.filter(c => c["day"] === "Lundi").map((item) => {
                                                return(
                                                    <Box>
                                                        <p>
                                                            {item.start} - {item.end} <br />
                                                            {item.subject.name} <br />                                    
                                                        </p>
                                                        <Divider sx={{bgcolor:'#306bff'}} /> 
                                                    </Box>
                                                )
                                            })}
                                        </td>

                                        <td style={{textAlign: "center"}}>
                                            {routine.filter(c => c["day"] === "Mardi").map((item) => {
                                                return(
                                                    <Box>
                                                        <p>
                                                            {item.start} - {item.end} <br />
                                                            {item.subject.name} <br />                                
                                                        </p>
                                                        <Divider sx={{bgcolor:'#306bff'}} /> 
                                                    </Box>
                                                )
                                            })}
                                        </td>

                                        <td style={{textAlign: "center"}}>
                                            {routine.filter(c => c["day"] === "Mercredi").map((item) => {
                                                return(
                                                    <Box>
                                                        <p>
                                                            {item.start} - {item.end} <br />
                                                            {item.subject.name} <br />                 
                                                        </p>
                                                        <Divider sx={{bgcolor:'#306bff'}} /> 
                                                    </Box>
                                                )
                                            })}
                                        </td>

                                        <td style={{textAlign: "center"}}>
                                            {routine.filter(c => c["day"] === "Jeudi").map((item) => {
                                                return(
                                                    <Box>
                                                        <p>
                                                            {item.start} - {item.end} <br />
                                                            {item.subject.name} <br />                                  
                                                        </p>
                                                        <Divider sx={{bgcolor:'#306bff'}} /> 
                                                    </Box>
                                                )
                                            })}
                                        </td>

                                        <td style={{textAlign: "center"}}>
                                            {routine.filter(c => c["day"] === "Vendredi").map((item) => {
                                                return(
                                                    <Box>
                                                        <p>
                                                            {item.start} - {item.end} <br />
                                                            {item.subject.name} <br />                                                        
                                                                                                                
                                                        </p>
                                                        <Divider sx={{bgcolor:'#306bff'}} /> 
                                                    </Box>
                                                )
                                            })}
                                        </td>

                                        <td style={{textAlign: "center"}}>
                                            {routine.filter(c => c["day"] === "Samedi").map((item) => {
                                                return(
                                                    <Box>
                                                        <p>
                                                            {item.start} - {item.end} <br />
                                                            {item.subject.name} <br />                        
                                                        </p>
                                                        <Divider sx={{bgcolor:'#306bff'}} /> 
                                                    </Box>
                                                )
                                            })}
                                        </td>

                                        <td style={{textAlign: "center"}}>
                                            {routine.filter(c => c["day"] === "Dimanche").map((item) => {
                                                return(
                                                    <Box>
                                                        <p>
                                                            {item.start} - {item.end} <br />
                                                            {item.subject.name} <br />                     
                                                        </p>
                                                        <Divider sx={{bgcolor:'#306bff'}} /> 
                                                    </Box>
                                                )
                                            })}
                                        </td>
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    </div>
                </div>}

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter un emploi du temps
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Jour"
                                        name="day"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={day || ''}
                                    >
                                        <MenuItem value="Lundi">Lundi</MenuItem>
                                        <MenuItem value="Mardi">Mardi</MenuItem>
                                        <MenuItem value="Mercredi">Mercredi</MenuItem>
                                        <MenuItem value="Jeudi">Jeudi</MenuItem>
                                        <MenuItem value="Vendredi">Vendredi</MenuItem>
                                        <MenuItem value="Samedi">Samedi</MenuItem>
                                        <MenuItem value="Dimanche">Dimanche</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Matière"
                                        name="subject_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={subject_id || ''}
                                    >
                                        {subjectYear.map((item) => {
                                            return <MenuItem value={item.subject_id}>{item.subject.name}</MenuItem>
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>                                   
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Début"
                                        style={{ width: '100%' }}
                                        value={start}
                                        onClick={this.handleClickStart.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>                                   
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Fin"
                                        style={{ width: '100%' }}
                                        value={end}
                                        onClick={this.handleClickEnd.bind(this)}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour ajouter
                        </Button> 
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Éditer un emploi du temps
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmitEdit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Jour"
                                        name="day"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={day || ''}
                                    >
                                        <MenuItem value="Lundi">Lundi</MenuItem>
                                        <MenuItem value="Mardi">Mardi</MenuItem>
                                        <MenuItem value="Mercredi">Mercredi</MenuItem>
                                        <MenuItem value="Jeudi">Jeudi</MenuItem>
                                        <MenuItem value="Vendredi">Vendredi</MenuItem>
                                        <MenuItem value="Samedi">Samedi</MenuItem>
                                        <MenuItem value="Dimanche">Dimanche</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Matière"
                                        name="subject_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={subject_id || ''}
                                    >
                                        {subjectYear.map((item) => {
                                            return <MenuItem value={item.subject_id}>{item.subject.name}</MenuItem>
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>                                   
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Début"
                                        style={{ width: '100%' }}
                                        value={start}
                                        onClick={this.handleClickStart.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>                                   
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Fin"
                                        style={{ width: '100%' }}
                                        value={end}
                                        onClick={this.handleClickEnd.bind(this)}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour modifier
                        </Button>  
                    </form>
                </Dialog>

                <Menu
                    elevation={12}
                    id="basic-menu"
                    anchorEl={anchorElStart}
                    open={Boolean(anchorElStart)}
                    onClose={() => {this.setState({ anchorElStart: null})}}
                    PaperProps={{
                        sx: {
                            width:200,
                            height:200,
                            mt:0,
                            ml: '-8px',
                        }
                    }}
                >
                    <TimePicker 
                        onChange={this.handleSelectStart.bind(this)}
                        value={start}
                        locale={locales['fr']}
                    />
                </Menu>

                <Menu
                    elevation={12}
                    id="basic-menu"
                    anchorEl={anchorElEnd}
                    open={Boolean(anchorElEnd)}
                    onClose={() => {this.setState({ anchorElEnd: null})}}
                    PaperProps={{
                        sx: {
                            width:200,
                            height:200,
                            mt:0,
                            ml: '-8px',
                        }
                    }}
                >
                    <TimePicker 
                        onChange={this.handleSelectEnd.bind(this)}
                        value={end}
                        locale={locales['fr']}
                    />
                </Menu>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>   
        )

    }
}
