import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import ReactToPrint from 'react-to-print';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

export default class Announcements extends Component {

    constructor(props) {
        super(props)
        this.state = {
            announcements: [],

            add: false,
            edit: false,
            id: false,
            name:'',
            description: '',
            debut: new Date(),
            fin: new Date(),

            severity: "",
            isAlert:false,
            loading: true,   

            anchorEl: [],          
            anchorElDatedebut: null,
            anchorElDatefin: null 
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Annonces"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/announces', config)
        .then(response => {
            this.setState({ 
                announcements: response.data.data,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){this.setState({isAlert:false}) }

    handleClickDatedebut(event){this.setState({anchorElDatedebut: event.currentTarget})}

    handleClickDatefin(event){this.setState({anchorElDatefin: event.currentTarget})}

    handleSelectDatedebut(date){ this.setState({debut: date, anchorElDatedebut: null}) }

    handleSelectDatefin(date){ this.setState({fin: date, anchorElDatefin: null}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({loading: true})   
            
        const bodyParameters = {
            name: this.state.name,
            debut: this.state.debut,
            fin: this.state.fin,
            year_id: this.props.year_id,
            description: this.state.description
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/announces',
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()            }                       
        }).catch(error => {
            this.setState({
                add: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    handleSubmitEdit(event){
        event.preventDefault()
        this.setState({loading: true})   
            
        const bodyParameters = {
            name: this.state.name,
            debut: this.state.debut,
            fin: this.state.fin,
            year_id: this.props.year_id,
            description: this.state.description
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        axios.put(MainUrl() + '/api/v1/announce/' + this.state.id,
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()            }                       
        }).catch(error => {
            this.setState({
                edit: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    edit(id){
        const item = this.state.announcements.filter(c => c["id"] === id)[0]
        this.setState({
            id: id,
            name: item.name,
            description: item.description,
            debut: new Date(item.debut),
            fin: new Date(item.fin),
            edit: true,
            anchorEl: [],   
        })
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/announce/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.componentDidMount()     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {

        const {
            announcements,
            add,
            edit,
            name,
            debut,
            fin,
            description,
            loading,
            severity,
            message,
            isAlert,
            anchorEl,
            anchorElDatedebut,
            anchorElDatefin
        } = this.state

        let announcement = announcements
        if(this.props.year_id !== null){
            announcement = announcement.filter(c => c["year_id"] === this.props.year_id)
        }

        const  getDate = (val) => {
            const item = new Date(val);
            const date = item.getDate();
            const month = item.getMonth() + 1;
            const year = item.getFullYear();
            
            return `${date}/${month}/${year}`;
        }

        let column = []
        if(this.props.accesses.approve){
            column = [
                { 
                    field: 'name', 
                    headerName: 'Titre',
                    flex: 1/2
                },
                { 
                    field: 'debut', 
                    headerName: 'Debut',
                    flex: 1/3,
                    renderCell: (cellValues) => { return getDate(cellValues.row.debut)}
                },
                { 
                    field: 'fin', 
                    headerName: 'Fin',
                    flex: 1/3,
                    renderCell: (cellValues) => { return getDate(cellValues.row.fin)}
                },
                { 
                    field: 'description', 
                    headerName: 'Description',
                    flex: 2
                },
                { 
                    field: 'activated', 
                    headerName: 'Statut',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <Tooltip title={cellValues.row.activated ? "Statut actif" : "Statut non actif"}>
                                <IconButton onClick={() => this.delete(cellValues.row.id)}>   
                                    <Switch checked={cellValues.row.activated} />
                                </IconButton> 
                            </Tooltip>                            
                        )                                  
                    }
                },
                { 
                    field: 'actions', 
                    headerName: 'Actions',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <React.Fragment>
                                <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                    <MoreVertIcon style={{color: "#306bff"}} />
                                </IconButton>

                                <Menu                                                            
                                    id={cellValues.row.id}
                                    anchorEl={anchorEl[cellValues.row.id]}
                                    keepMounted
                                    open={Boolean(anchorEl[cellValues.row.id])}
                                    onClose={() => this.setState({anchorEl: []})}    
                                    PaperProps={{
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 0,
                                            ml:1,
                                            width:150
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {this.props.accesses.write ||
                                        <MenuItem onClick={() => { this.edit(cellValues.row.id) }} disabled={!this.props.accesses.write}>
                                            <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                        </MenuItem>
                                    }                                
                                </Menu>
                            </React.Fragment>
                        )
                    }
                }
            ]
        }else{
            column = [
                { 
                    field: 'name', 
                    headerName: 'Titre',
                    flex: 1/2
                },
                { 
                    field: 'debut', 
                    headerName: 'Debut',
                    flex: 1/3,
                    renderCell: (cellValues) => { return getDate(cellValues.row.debut)}
                },
                { 
                    field: 'fin', 
                    headerName: 'Fin',
                    flex: 1/3,
                    renderCell: (cellValues) => { return getDate(cellValues.row.fin)}
                },
                { 
                    field: 'description', 
                    headerName: 'Description',
                    flex: 2
                },
                { 
                    field: 'actions', 
                    headerName: 'Actions',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <React.Fragment>
                                <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                    <MoreVertIcon style={{color: "#306bff"}} />
                                </IconButton>

                                <Menu                                                            
                                    id={cellValues.row.id}
                                    anchorEl={anchorEl[cellValues.row.id]}
                                    keepMounted
                                    open={Boolean(anchorEl[cellValues.row.id])}
                                    onClose={() => this.setState({anchorEl: []})}    
                                    PaperProps={{
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 0,
                                            ml:1,
                                            width:150
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {this.props.accesses.write ||
                                        <MenuItem onClick={() => { this.edit(cellValues.row.id) }} disabled={!this.props.accesses.write}>
                                            <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                        </MenuItem>
                                    }                                
                                </Menu>
                            </React.Fragment>
                        )
                    }
                }
            ]
        }

        let Dataannouncement = announcement
        if(this.props.search !== ''){
            let result = []
            Dataannouncement.map((elem, index) => {
                elem.search = elem.name + ' ' + elem.description + ' ' + getDate(elem.debut) + ' ' + getDate(elem.fin)
                result = result.concat(elem)
            })
            Dataannouncement = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        const displaydate = (value) => {
            var date = new Date(value);
            if(date.getMonth() == 0){
                return date.getDate() + " janvier " + date.getFullYear()
            }
            if(date.getMonth() == 1){
                return date.getDate() + " Février " + date.getFullYear()
            }
            if(date.getMonth() == 2){
                return date.getDate() + " Mars " + date.getFullYear()
            }
            if(date.getMonth() == 3){
                return date.getDate() + " Avril " + date.getFullYear()
            }
            if(date.getMonth() == 4){
                return date.getDate() + " Mai " + date.getFullYear()
            }
            if(date.getMonth() == 5){
                return date.getDate() + " Juin " + date.getFullYear()
            }
            if(date.getMonth() == 6){
                return date.getDate() + " Juillet " + date.getFullYear()
            }
            if(date.getMonth() == 7){
                return date.getDate() + " Août " + date.getFullYear()
            }
            if(date.getMonth() == 8){
                return date.getDate() + " Septembre " + date.getFullYear()
            }
            if(date.getMonth() == 9){
                return date.getDate() + " Octobre " + date.getFullYear()
            }
            if(date.getMonth() == 10){
                return date.getDate() + " Novembre " + date.getFullYear()
            }
            if(date.getMonth() == 11){
                return date.getDate() + " Décembre " + date.getFullYear()
            }
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end", alignItems: 'center'}}>
                    {!this.props.accesses.write ||
                        <Button 
                            variant="contained" 
                            disabled={!this.props.accesses.write} 
                            onClick={() => this.setState({add: true})} 
                            sx={{height:40, boxShadow: 10, borderRadius:1, bgcolor: '#306bff', mr:2}}
                        >
                            Ajouter une annonce
                        </Button> 
                    }

                    <ReactToPrint
                        trigger={() => <IconButton size="large"><LocalPrintshopIcon fontSize="inherit" /></IconButton>}
                        content={() => this.componentRef}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={Dataannouncement}
                            columns={column}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Ligne par page'
                                }
                            }}
                        />
                    </Card>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter une annonce
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Titre d'annonce"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6} md={6}>                                   
                                    <TextField
                                        id="outlined-required"
                                        label="Date de debut"
                                        style={{ width: '100%' }}
                                        value={displaydate(debut)}
                                        onClick={this.handleClickDatedebut.bind(this)} 
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6} md={6}>                                   
                                    <TextField
                                        id="outlined-required"
                                        label="Date de fin"
                                        style={{ width: '100%' }}
                                        value={displaydate(fin)}
                                        onClick={this.handleClickDatefin.bind(this)} 
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField  
                                        id="outlined-multiline-static"
                                        label="Description"
                                        multiline
                                        rows={4}
                                        name="description"
                                        style={{ width: '100%' }}
                                        value={description}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>                                
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>   
                    </form>

                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Éditer une annonce
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmitEdit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Titre d'annonce"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6} md={6}>                                   
                                    <TextField
                                        id="outlined-required"
                                        label="Date de debut"
                                        style={{ width: '100%' }}
                                        value={displaydate(debut)}
                                        onClick={this.handleClickDatedebut.bind(this)} 
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6} md={6}>                                   
                                    <TextField
                                        id="outlined-required"
                                        label="Date de fin"
                                        style={{ width: '100%' }}
                                        value={displaydate(fin)}
                                        onClick={this.handleClickDatefin.bind(this)} 
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField  
                                        id="outlined-multiline-static"
                                        label="Description"
                                        multiline
                                        rows={4}
                                        name="description"
                                        style={{ width: '100%' }}
                                        value={description}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>                                
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>

                {Dataannouncement.length > 0 && <div  style={{ display: "none", width:"100%" }}> 
                    <div ref={el => (this.componentRef = el)}>
                        <Grid container spacing={3} p={2}>                            
                            <Grid item xs={12}>
                                <div style={{width:'100%', textAlign:'center'}}>
                                    <Typography variant="h4" component="div">Les annonces</Typography>
                                </div>

                                <table className="table table-hover" style={{width:"100%", textAlign:'left', marginTop:8}}>
                                    <thead> 
                                        <tr>
                                            <th style={{border: '1px solid black', borderCollapse:'collapse' }}>Titre</th>
                                            <th style={{border: '1px solid black', borderCollapse:'collapse' }}>Debut</th>
                                            <th style={{border: '1px solid black', borderCollapse:'collapse' }}>Fin</th>
                                            <th style={{border: '1px solid black', borderCollapse:'collapse' }}>Description</th>
                                            <th style={{border: '1px solid black', borderCollapse:'collapse' }}>Statut</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {Dataannouncement.map((item, index) => (
                                            <tr>
                                                <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{item.name}</td>
                                                <td style={{border: '1px solid black', borderCollapse:'collapse'}}>{getDate(item.debut)}</td>
                                                <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{getDate(item.fin)}</td>
                                                <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{item.description}</td>
                                                <td style={{border: '1px solid black', borderCollapse:'collapse' }}><Switch checked={item.activated} /></td>
                                            </tr> 
                                        ))}
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    </div>
                </div>}

                <Menu
                    elevation={12}
                    id="basic-menu"
                    anchorEl={anchorElDatedebut}
                    open={Boolean(anchorElDatedebut)}
                    onClose={() => {this.setState({ anchorElDatedebut: null})}}
                    PaperProps={{
                        sx: {
                            mt:4,
                            ml: '-16px',
                        }
                    }}
                >
                    <Calendar
                        date={debut}
                        onChange={this.handleSelectDatedebut.bind(this)}
                        locale={locales['fr']}
                    />
                </Menu>

                <Menu
                    elevation={12}
                    id="basic-menu"
                    anchorEl={anchorElDatefin}
                    open={Boolean(anchorElDatefin)}
                    onClose={() => {this.setState({ anchorElDatefin: null})}}
                    PaperProps={{
                        sx: {
                            mt:4,
                            ml: '-16px',
                        }
                    }}
                >
                    <Calendar
                        date={fin}
                        onChange={this.handleSelectDatefin.bind(this)}
                        locale={locales['fr']}
                    />
                </Menu>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}