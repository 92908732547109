import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogContentText from '@mui/material/DialogContentText';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Divider from '@mui/material/Divider';

export default class Teachers extends Component{

    constructor(props) {
        super(props)
        this.state = {
            teachers: [],
            presences: [],
            lecons: [],

            add: false,
            edit: false,
            show: false,
            shown: false,
            id: null,
            user_id: null,
            matricule: "",
            genre: "M",
            first_name: "",
            second_name: "",
            designation: "",
            email: "",
            phone: "",
            adresse: "",
            birthdate: new Date(),
            birthplace: "",
            password:"",
            passwordc: "",
            showPassword: false,
            showPasswordc: false,


            subject_id: null,
            classe_id: null,
            cycle_id: null,
            teacher_id: null,
            date: new Date(),
            hour: "",
            pci: "",
            pt: "",
            po: "",
            pe: "",
            tu: "",
            ee: "",
            cc: "",
            ev: "",
            og: "",

            matricule: "",
            first_name: "",
            second_name: "",
            classe:"",
            subject: "",

            valuetab: 1,             

            severity: "",
            message: "",
            isAlert:false,
            loading: true,
            anchorEl: [],
            anchorElDate: null,
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Enseingants"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/teachers', config)
        .then(response => {
            this.setState({ 
                teachers: response.data.data,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    handleChangetab(event, newValue){ this.setState({ valuetab: newValue}) }

    isAlertChange(){ this.setState({isAlert:false}) }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClickDate(event){this.setState({anchorElDate: event.currentTarget})}

    handleSelectDate(date){ this.setState({birthdate: date, anchorElDate: null}) }

    handleSubmit(event) {
        event.preventDefault()
        if(this.state.password !== this.state.passwordc){
            this.setState({ 
                loading: false,
                severity: 'error',
                message: "Les deux mots de passe ne sont pas identiques",
                isAlert: true
            })
        }else{
            this.setState({loading: true})   

            const bodyParameters = {
                matricule: this.state.matricule,
                first_name: this.state.first_name,
                second_name: this.state.second_name,
                designation: this.state.designation,
                email: this.state.email,
                phone: this.state.phone,
                adresse: this.state.adresse,
                genre: this.state.genre,
                birthdate: this.state.birthdate,
                birthplace: this.state.birthplace,
                password: this.state.password
            }

            const token = JSON.parse(localStorage.getItem("TOKEN"))
            const config = {
                headers: {'Authorization': 'Bearer '+token}
            }
            axios.post(MainUrl() + '/api/v1/teacher',
                bodyParameters,
                config
            ).then(response => {
                if(response.data.statusCode === 201){
                    this.setState({
                        loading: false,
                        severity: 'error',
                        message: response.data.message,
                        isAlert: true
                    }) 
                } else{
                    this.setState({
                        add: false,
                        loading: false,
                        severity: 'success',
                        message: response.data.message,
                        isAlert: true
                    }) 
                    this.componentDidMount()           
                }                       
            }).catch(error => {
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'error',
                    message: error.response.data?.message || "Une erreur inattendue s'est produite",
                    isAlert: true
                });
            });
        }
    }

    handleSubmitEdit(event){
        event.preventDefault()
        if(this.state.password !== this.state.passwordc){
            this.setState({ 
                loading: false,
                severity: 'error',
                message: "Les deux mots de passe ne sont pas identiques",
                isAlert: true
            })
        }else{
            this.setState({loading: true})   
                
            const bodyParameters = {
                user_id: this.state.user_id,
                matricule: this.state.matricule,
                first_name: this.state.first_name,
                second_name: this.state.second_name,
                designation: this.state.designation,
                email: this.state.email,
                phone: this.state.phone,
                adresse: this.state.adresse,
                genre: this.state.genre,
                birthdate: this.state.birthdate,
                birthplace: this.state.birthplace,
                password: this.state.password
            }

            const token = JSON.parse(localStorage.getItem("TOKEN"))
            const config = {
                headers: {'Authorization': 'Bearer '+token}
            }

            axios.put(MainUrl() + '/api/v1/teacher/' + this.state.id,
                bodyParameters,
                config
            ).then(response => {
                if(response.data.statusCode === 201){
                    this.setState({
                        loading: false,
                        severity: 'error',
                        message: response.data.message,
                        isAlert: true
                    }) 
                } else{
                    this.setState({
                        edit: false,
                        loading: false,
                        severity: 'success',
                        message: response.data.message,
                        isAlert: true
                    }) 
                    this.componentDidMount()            
                }                       
            }).catch(error => {
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'error',
                    message: error.response.data?.message || "Une erreur inattendue s'est produite",
                    isAlert: true
                });
            });
        }
    }

    show(id){
        this.setState({loading: true}) 
        const item = this.state.teachers.filter(c => c["id"] === id)[0]

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.get(MainUrl() + '/api/v1/teacher/details/' + id,
            config
        )
        .then(response => {
            this.setState({ 
                id: id,
                valuetab: 1, 
                user_id: item.user_id,
                matricule: item.user.matricule,
                first_name: item.user.first_name,
                second_name: item.user.second_name,
                genre: item.sexe,
                birthdate: new Date(item.birthdate),
                birthplace: item.birthplace,
                phone: item.phone,
                email: item.email,
                adresse: item.adresse,
                designation: item.designation,
                presences: response.data.presences,
                lecons: response.data.lecons,

                show: true,
                loading: false,
                anchorEl: [], 
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        });
    }

    edit(id){
        const item = this.state.teachers.filter(c => c["id"] === id)[0]

        this.setState({
            id: id,
            user_id: item.user_id,
            matricule: item.user.matricule,
            first_name: item.user.first_name,
            second_name: item.user.second_name,
            designation: item.designation,
            genre: item.sexe,
            email: item.email,
            phone: item.phone,
            adresse: item.adresse,
            birthdate: new Date(item.birthdate),
            birthplace: item.birthplace,
            password:"",
            passwordc: "",
            edit: true,
            anchorEl: [],   
        })
    }

    shown(id){
        this.setState({loading: true}) 
        const item = this.state.lecons.filter(c => c["id"] === id)[0]
        this.setState({ 
            classe: item.classe.name,
            subject:item.subject.name,
            user_id: item.user_id,
            subject_id: item.subject_id,
            classe_id: item.classe_id,
            cycle_id: item.cycle_id,
            teacher_id: item.teacher_id,
            date: new Date(item.date),
            hour: item.hour,
            pci: item.pci,
            pt: item.pt,
            po: item.po,
            pe: item.pe,
            tu: item.tu,
            ee: item.ee,
            cc: item.cc,
            ev: item.ev,
            og: item.og,
            shown: true,
            loading: false,
        })
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/teacher/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.componentDidMount()     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {    

        const {
            teachers,
            presences,
            lecons,
            add,
            edit,
            show,
            valuetab,
            matricule,
            genre,
            first_name,
            second_name,
            designation,
            email,
            phone,
            adresse,
            birthdate,
            birthplace,
            password,
            passwordc,
            showPassword,
            showPasswordc,

            shown,
            classe,
            subject,
            user_id,
            subject_id,
            classe_id,
            cycle_id,
            teacher_id,
            date,
            hour,
            pci,
            pt,
            po,
            pe,
            tu,
            ee,
            cc,
            ev,
            og,

            loading,
            severity,
            message,
            isAlert,
            anchorEl,
            anchorElDate,
        } = this.state 

        let column = []
        if(this.props.accesses.approve){ 
            column = [
                { 
                    field: 'matricule', 
                    headerName: 'Matricule',
                    flex: 1/2,
                    renderCell: (cellValues) => { return cellValues.row.user.matricule}
                },
                { 
                    field: 'first_name', 
                    headerName: 'Prénom',
                    flex: 1,
                    renderCell: (cellValues) => { return cellValues.row.user.first_name}
                },
                { 
                    field: 'second_name', 
                    headerName: 'Nom de famille',
                    flex: 1,
                    renderCell: (cellValues) => { return cellValues.row.user.second_name}
                },
                { 
                    field: 'activated', 
                    headerName: 'Statut',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <Tooltip title={cellValues.row.activated ? "Statut actif" : "Statut non actif"}>
                                <IconButton onClick={() => this.delete(cellValues.row.id)} >   
                                    <Switch checked={cellValues.row.activated}/>
                                </IconButton> 
                            </Tooltip>                            
                        )                          
                    }
                },
                { 
                    field: 'actions', 
                    headerName: 'Actions',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <React.Fragment>
                                <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                    <MoreVertIcon style={{color: "#306bff"}} />
                                </IconButton>

                                <Menu                                                            
                                    id={cellValues.row.id}
                                    anchorEl={anchorEl[cellValues.row.id]}
                                    keepMounted
                                    open={Boolean(anchorEl[cellValues.row.id])}
                                    onClose={() => this.setState({anchorEl: []})}    
                                    PaperProps={{
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 0,
                                            ml:1,
                                            width:150
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem  onClick={() => { this.show(cellValues.row.id) }}>
                                        <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                    </MenuItem>

                                    {!this.props.accesses.write ||                                    
                                        <MenuItem onClick={() => { this.edit(cellValues.row.id) }}>
                                            <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                        </MenuItem>
                                    }
                                </Menu>
                            </React.Fragment>
                        )
                    }
                }
            ]
        }else{
            column = [
                { 
                    field: 'matricule', 
                    headerName: 'Matricule',
                    flex: 1/2,
                    renderCell: (cellValues) => { return cellValues.row.user.matricule}
                },
                { 
                    field: 'first_name', 
                    headerName: 'Prénom',
                    flex: 1,
                    renderCell: (cellValues) => { return cellValues.row.user.first_name}
                },
                { 
                    field: 'second_name', 
                    headerName: 'Nom de famille',
                    flex: 1,
                    renderCell: (cellValues) => { return cellValues.row.user.second_name}
                },
                { 
                    field: 'actions', 
                    headerName: 'Actions',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <React.Fragment>
                                <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                    <MoreVertIcon style={{color: "#306bff"}} />
                                </IconButton>

                                <Menu                                                            
                                    id={cellValues.row.id}
                                    anchorEl={anchorEl[cellValues.row.id]}
                                    keepMounted
                                    open={Boolean(anchorEl[cellValues.row.id])}
                                    onClose={() => this.setState({anchorEl: []})}    
                                    PaperProps={{
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 0,
                                            ml:1,
                                            width:150
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem  onClick={() => { this.show(cellValues.row.id) }}>
                                        <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                    </MenuItem>

                                    {!this.props.accesses.write ||                                    
                                        <MenuItem onClick={() => { this.edit(cellValues.row.id) }}>
                                            <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                        </MenuItem>
                                    }
                                </Menu>
                            </React.Fragment>
                        )
                    }
                }
            ]
        }

        let DataTeachers = teachers
        if(this.props.search !== ''){
            let result = []
            DataTeachers.map((elem, index) => {
                elem.search = elem.user.matricule + ' ' + elem.user.first_name + ' ' + elem.user.second_name + ' ' + elem.sexe
                result = result.concat(elem)
            })
            DataTeachers = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        const displaydate = (value) => {
            var date = new Date(value);
            if(date.getMonth() == 0){
                return date.getDate() + " janvier " + date.getFullYear()
            }
            if(date.getMonth() == 1){
                return date.getDate() + " Février " + date.getFullYear()
            }
            if(date.getMonth() == 2){
                return date.getDate() + " Mars " + date.getFullYear()
            }
            if(date.getMonth() == 3){
                return date.getDate() + " Avril " + date.getFullYear()
            }
            if(date.getMonth() == 4){
                return date.getDate() + " Mai " + date.getFullYear()
            }
            if(date.getMonth() == 5){
                return date.getDate() + " Juin " + date.getFullYear()
            }
            if(date.getMonth() == 6){
                return date.getDate() + " Juillet " + date.getFullYear()
            }
            if(date.getMonth() == 7){
                return date.getDate() + " Août " + date.getFullYear()
            }
            if(date.getMonth() == 8){
                return date.getDate() + " Septembre " + date.getFullYear()
            }
            if(date.getMonth() == 9){
                return date.getDate() + " Octobre " + date.getFullYear()
            }
            if(date.getMonth() == 10){
                return date.getDate() + " Novembre " + date.getFullYear()
            }
            if(date.getMonth() == 11){
                return date.getDate() + " Décembre " + date.getFullYear()
            }
        }

        let years = presences.filter( (ele, ind) => ind === presences.findIndex(elem => elem.year_id === ele.year_id))

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    {!this.props.accesses.write ||
                        <Button 
                            variant="contained"
                            onClick={() => this.setState({add: true})} 
                            sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff'}}
                        >
                            Ajouter un(e) Enseignant(e)s
                        </Button>    
                    }
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:620, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={DataTeachers}
                            columns={column}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Ligne par page'
                                }
                            }}
                        />
                    </Card>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter un(e) enseignant(e)
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Matricule"
                                        name="matricule"
                                        style={{ width: '100%' }}
                                        value={matricule}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Sexe"
                                        name="genre"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={genre}
                                    >
                                        <MenuItem key="Masculin" value="M">Masculin</MenuItem>
                                        <MenuItem key="Féminin" value="F">Féminin</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Prénom"
                                        name="first_name"
                                        style={{ width: '100%' }}
                                        value={first_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de famille"
                                        name="second_name"
                                        style={{ width: '100%' }}
                                        value={second_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Spécialités"
                                        name="designation"
                                        style={{ width: '100%' }}
                                        value={designation}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Adresse email"
                                        name="email"
                                        style={{ width: '100%' }}
                                        value={email}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Téléphone"
                                        name="phone"
                                        style={{ width: '100%' }}
                                        value={phone}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Adresse"
                                        name="adresse"
                                        style={{ width: '100%' }}
                                        value={adresse}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6}>                                    
                                    <TextField
                                        id="outlined-required"
                                        label="Date de naissance"
                                        style={{ width: '100%' }}
                                        value={displaydate(birthdate)}
                                        onClick={this.handleClickDate.bind(this)} 
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Lieu de naissance"
                                        name="birthplace"
                                        style={{ width: '100%' }}
                                        value={birthplace}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="password"
                                        label="Nouveau mot de passe"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPassword:! showPassword})} 
                                                    >
                                                        {showPassword ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="passwordc"
                                        label="Mot de passe confirmation"
                                        type={showPasswordc ? 'text' : 'password'}
                                        value={passwordc}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPasswordc:! showPasswordc})} 
                                                    >
                                                        {showPasswordc ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Éditer un(e) enseignant(e)
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmitEdit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Matricule"
                                        name="matricule"
                                        style={{ width: '100%' }}
                                        value={matricule}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Sexe"
                                        name="genre"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={genre}
                                    >
                                        <MenuItem key="Masculin" value="M">Masculin</MenuItem>
                                        <MenuItem key="Féminin" value="F">Féminin</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Prénom"
                                        name="first_name"
                                        style={{ width: '100%' }}
                                        value={first_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de famille"
                                        name="second_name"
                                        style={{ width: '100%' }}
                                        value={second_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Spécialités"
                                        name="designation"
                                        style={{ width: '100%' }}
                                        value={designation}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Adresse email"
                                        name="email"
                                        style={{ width: '100%' }}
                                        value={email}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Téléphone"
                                        name="phone"
                                        style={{ width: '100%' }}
                                        value={phone}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Adresse"
                                        name="adresse"
                                        style={{ width: '100%' }}
                                        value={adresse}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6}>                                    
                                    <TextField
                                        id="outlined-required"
                                        label="Date de naissance"
                                        style={{ width: '100%' }}
                                        value={displaydate(birthdate)}
                                        onClick={this.handleClickDate.bind(this)} 
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-required"
                                        label="Lieu de naissance"
                                        name="birthplace"
                                        style={{ width: '100%' }}
                                        value={birthplace}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="password"
                                        label="Nouveau mot de passe"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPassword:! showPassword})} 
                                                    >
                                                        {showPassword ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="passwordc"
                                        label="Mot de passe confirmation"
                                        type={showPasswordc ? 'text' : 'password'}
                                        value={passwordc}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPasswordc:! showPasswordc})} 
                                                    >
                                                        {showPasswordc ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={show}
                    onClose={() => this.setState({show: false})}
                    scroll={'paper'}
                >
                    <DialogTitle>{'[' + matricule + ']< < < < < ' + first_name + '< < ' + second_name}</DialogTitle>

                    <DialogContent dividers={true}>
                        <DialogContentText tabIndex={-1} >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TabContext value={valuetab}>
                                        <Box sx={{  borderColor: 'divider'}}>
                                            <TabList onChange={this.handleChangetab.bind(this)} variant="scrollable" scrollButtons="auto">
                                                <Tab label="Données d’identité" value={1} />  

                                                <Tab label="Présence" value={2} />

                                                <Tab label="Suivi de lecon" value={3} />
                                            </TabList>
                                        </Box>

                                        <TabPanel value={1} sx={{p:2, minHeight:"60vh"}}>
                                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Matricule:</strong> {matricule}</Typography>
                                                    <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Prénom:</strong> {first_name}</Typography>
                                                    <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Nom:</strong> {second_name}</Typography>
                                                    <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Sexe:</strong> {genre}</Typography>
                                                    <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Date de naissance:</strong> {displaydate(birthdate)}</Typography>
                                                    <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Lieu de naissance:</strong> {birthplace}</Typography>
                                                    <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Spécialités:</strong> {designation}</Typography>
                                                    <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Adresse email:</strong> {email}</Typography> 
                                                    <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Téléphone:</strong> {phone}</Typography> 
                                                    <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Adresse:</strong> {adresse}</Typography> 
                                                    
                                                    {years.length > 0 && <Divider sx={{my:2}}>Année académique</Divider>}

                                                    {years.map((item, index) => (
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Année académique:</strong> {item.year.name}</Typography> 
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </TabPanel>

                                        <TabPanel value={2} sx={{p:2, minHeight:"60vh"}}>
                                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    {years.map((item, index) => (
                                                        <React.Fragment>
                                                            <Divider sx={{my:2}}>Année académique: {item.year.name}</Divider>
                                                            
                                                            <table className="table table-bordered">
                                                                <thead >
                                                                    <tr>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Date</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Heure</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Matière</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Classe</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Nb d'heure</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Etat</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Etablissement</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Taux horaire</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Montant</Typography></th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {presences.filter(c => c["year_id"] === item.year_id).map((elem, index) => (
                                                                        <tr>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{displaydate(new Date(elem.date))}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{elem.hour}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{elem.subject.name}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{elem.classe.name}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{elem.nbhours}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{elem.activated ? "Effectuée" : "Non effectuée"}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{elem.etablissement}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{elem.taux.toLocaleString()}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{(elem.nbhours * elem.taux).toLocaleString()}</Typography></td>
                                                                        </tr>                                                                       
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </React.Fragment>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </TabPanel>

                                        <TabPanel value={3} sx={{p:2, minHeight:"60vh"}}>
                                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    {years.map((item, index) => (
                                                        <React.Fragment>
                                                            <Divider sx={{my:2}}>Année académique: {item.year.name}</Divider>

                                                            <table className="table table-bordered">
                                                                <thead >
                                                                    <tr>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Date</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Heure</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Matière</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Classe</Typography></th>
                                                                        <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Details</Typography></th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {lecons.filter(c => c["year_id"] === item.year_id).map((elem, index) => (
                                                                        <tr>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{displaydate(new Date(elem.date))}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{elem.hour}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{elem.subject.name}</Typography></td>
                                                                            <td><Typography color="text.secondary" sx={{fontSize:16 }}>{elem.classe.name}</Typography></td>
                                                                            <td>
                                                                                <Button
                                                                                    fullWidth
                                                                                    onClick={() => {{this.shown(elem.id)}}}
                                                                                    variant="contained"
                                                                                    sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#306bff', color:'white'}}}
                                                                                >
                                                                                    Details
                                                                                </Button>
                                                                            </td>
                                                                        </tr>                                                                       
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </React.Fragment>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    </TabContext>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button fullWidth onClick={() => this.setState({show: false})}>Fermer</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={shown}
                    onClose={() => this.setState({shown: false})}
                    scroll={'paper'}
                >
                    <DialogTitle sx={{display: 'flex', justifyContent:'center'}}>FICHER DE SUIVI DE LECON</DialogTitle>

                    <DialogContent dividers={true}>
                        <DialogContentText tabIndex={-1} >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end", alignItems: 'center'}}>
                                    <Typography color="text.secondary" sx={{fontSize:16}}>Bamako, le {displaydate(new Date(date))}</Typography>                                      
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"space-between", alignItems: 'center'}}>
                                    <Typography color="text.secondary" sx={{fontSize:16}}>CLASSE: {classe}</Typography>  
                                    <Typography color="text.secondary" sx={{fontSize:16}}>DISCIPLINE: {subject}</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}>Heure: {hour}</Typography>                                     
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16}}>PROFESSEUR: {matricule} | {first_name} {second_name}</Typography> 
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700}}>I. PRESENTATION GENERALE DU COURS</Typography> 
                                </Grid> 

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:600}}> Présentation du centre d'intérêt</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {pci}</Typography>    
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:600}}> Présentation du thème</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {pt}</Typography>   
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:600}}> Présentation des objectifs</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {po}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700}}>II. INTRODUCTION DE LA LEÇON</Typography> 
                                </Grid> 

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:600}}> Pré évaluation(Pré requis des élèves)</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {pe}</Typography>    
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700}}>III. STRATEGIES</Typography> 
                                </Grid> 

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:600}}> Techniques utilisée</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {tu}</Typography>    
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:600}}> Echanges avec les élèves / Feed-back</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {ee}</Typography>    
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700}}>IV. CONTENUS DES COURS (QUALITE DES CONTENUS)</Typography> 
                                </Grid> 

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {cc}</Typography>    
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700}}>V. EVALUATION</Typography> 
                                </Grid> 

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {ev}</Typography>    
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700}}>VI. OBSERVATION GENERALE</Typography> 
                                </Grid> 

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {og}</Typography>    
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button fullWidth onClick={() => this.setState({shown: false})}>Fermer</Button>
                    </DialogActions>
                </Dialog>

                <Menu
                    elevation={12}
                    id="basic-menu"
                    anchorEl={anchorElDate}
                    open={Boolean(anchorElDate)}
                    onClose={() => {this.setState({ anchorElDate: null})}}
                    PaperProps={{
                        sx: {
                            mt:4,
                            ml: '-16px',
                        }
                    }}
                >
                    <Calendar
                        date={birthdate}
                        onChange={this.handleSelectDate.bind(this)}
                        locale={locales['fr']}
                    />
                </Menu>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}