import React, { Component } from "react";
import Grid from '@mui/material/Grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName, FirstLine, SecondLine, ThirdLine, FourthLine, FifthLine, SixthLine, SeventhLine, EighthLine} from '../../settings'
import Typography from '@mui/material/Typography';
import SelectClasse from '../SelectClasse'
import Card from '@mui/material/Card';
import ReactToPrint from 'react-to-print';
import IconButton from '@mui/material/IconButton';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

export default class Propositions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notes: [],
            subjectsYears: [],
            CompositionYear: [],
            students: [],

            classe_id: null,
            cycle_id: null,
            composition_id: null,

            show: false,

            anchorEl: [],   
            loading: true,
            severity: "",
            message: "",
            isAlert:false
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Proposition de passage"
        this.setState({loading: false}) 
    }

    handleChangeClasse(data){
        if(data.name === 'classe_id'){
            this.setState({ 
                classe_id: data.classe_id,
                cycle_id: data.cycle_id,
                loading: true,
            }) 
            this.getData(data.classe_id, data.cycle_id)
        }        
    }

    getData(classe_id, cycle_id){ 
        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: classe_id,
            cycle_id: cycle_id
        }
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/notes-year', 
            bodyParameters, 
            config
        )
        .then(response => {
            this.setState({ 
                notes: response.data.data,
                subjectsYears: response.data.subjectsYears,
                CompositionYear: response.data.CompositionYear,
                students: response.data.students,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    render() {  

        const {
            notes,
            subjectsYears,
            // CompositionYear,
            classe_id,
            students,
            loading,
            severity,
            message,
            isAlert,
        } = this.state

        let subjectsYear = subjectsYears.filter( (ele, ind) => ind === subjectsYears.findIndex(elem => elem.subject_id === ele.subject_id))

        let CompositionYear = this.state.CompositionYear.filter( (ele, ind) => ind === this.state.CompositionYear.findIndex(elem => elem.composition_id === ele.composition_id))

        const getNoteCl = (student_id, subject_id, composition_id) => {
            let resultat = 0
            let item = notes.filter(c => (c["student_id"] === student_id && c["subject_id"] === subject_id && c["composition_id"] === composition_id))[0]
            resultat = item.moyen_classe
            return resultat
        }

        const getNoteCompo = (student_id, subject_id, composition_id) => {
            let resultat = 0
            let item = notes.filter(c => (c["student_id"] === student_id && c["subject_id"] === subject_id && c["composition_id"] === composition_id))[0]
            resultat = item.moyen_compo
            return resultat
        }

        const getNoteGen = (student_id, subject_id, composition_id) => {
            return (getNoteCl(student_id, subject_id, composition_id) + getNoteCompo(student_id, subject_id, composition_id))/3
        }

        const getNoteGenCoef = (student_id, subject_id, composition_id) => {
            let resultat = 0
            let coef = 0
            let item = notes.filter(c => (c["student_id"] === student_id && c["subject_id"] === subject_id && c["composition_id"] === composition_id))[0]
            coef = item.coef
            resultat = getNoteGen(student_id, subject_id, composition_id) * coef
            return resultat
        }

        const GetTotalGen = (student_id, composition_id) => {
            let resultat = 0
            subjectsYear.map((subject, index) => {
                resultat = resultat + getNoteGenCoef(student_id, subject.subject_id, composition_id)
            })
            return resultat
        }

        const GetMoyenneGenere = (student_id, composition_id) => {
            let resultat = 0
            let coef = 0
            subjectsYear.map((subject, index) => {               
                let item = notes.filter(c => (c["student_id"] === student_id && c["subject_id"] === subject.subject_id && c["composition_id"] === composition_id))[0]
                coef = coef + item.coef
            })            
            resultat = GetTotalGen(student_id, composition_id)/coef
            return resultat
        }

        const getMoyenneAnnuelle = (student_id) => {
            let resultat = 0
            let i = 0
            CompositionYear.map((element) => {
                i = i + 1
                resultat = resultat + GetMoyenneGenere(student_id, element.composition_id)
            })
            resultat = resultat/i
            return resultat
        }

        const getNotApreciation = (note) => {
            if(note >= 10){return "Passe"}
            else{return "Rédouble"}
        }

        const displaydate = (value) => {
            var date = new Date(value);
            if(date.getMonth() == 0){
                return date.getDate() + " janvier " + date.getFullYear()
            }
            if(date.getMonth() == 1){
                return date.getDate() + " Février " + date.getFullYear()
            }
            if(date.getMonth() == 2){
                return date.getDate() + " Mars " + date.getFullYear()
            }
            if(date.getMonth() == 3){
                return date.getDate() + " Avril " + date.getFullYear()
            }
            if(date.getMonth() == 4){
                return date.getDate() + " Mai " + date.getFullYear()
            }
            if(date.getMonth() == 5){
                return date.getDate() + " Juin " + date.getFullYear()
            }
            if(date.getMonth() == 6){
                return date.getDate() + " Juillet " + date.getFullYear()
            }
            if(date.getMonth() == 7){
                return date.getDate() + " Août " + date.getFullYear()
            }
            if(date.getMonth() == 8){
                return date.getDate() + " Septembre " + date.getFullYear()
            }
            if(date.getMonth() == 9){
                return date.getDate() + " Octobre " + date.getFullYear()
            }
            if(date.getMonth() == 10){
                return date.getDate() + " Novembre " + date.getFullYear()
            }
            if(date.getMonth() == 11){
                return date.getDate() + " Décembre " + date.getFullYear()
            }
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    <SelectClasse 
                        year_id={this.props.year_id} 
                        onChange={this.handleChangeClasse.bind(this)} 
                        width={200} 
                        mr={classe_id == null ? 0 : 2} 
                    />

                    {classe_id == null ||
                        <ReactToPrint
                            trigger={() => <IconButton disabled={classe_id == null} size="large"><LocalPrintshopIcon fontSize="inherit" /></IconButton>}
                            content={() => this.componentRef}
                        />  
                    }
                </Grid>

                <Grid item xs={12} md={12} lg={12}>                
                    <Card elevation={6}  sx={{minHeight:570, backgroundColor:'#ffffff', borderRadius:2, padding:2}} p={2}>
                        <div style={{width:'100%'}}>
                            <div style={{width:'100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <div style={{ textAlign:'center', padding: 16}}>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{FirstLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>*****</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{SecondLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{ThirdLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>*****</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{FourthLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{FifthLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{SixthLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{SeventhLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{EighthLine()}</Typography>
                                </div >

                                <div style={{ textAlign:'center', padding: 16}}>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>REPUBLIQUE DU MALI</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>Un Peuple- Un But- Une Foi</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>*****</Typography>
                                </div>                                        
                            </div>

                            <div style={{width:'100%', textAlign:'center', border: '2px solid black'}}>
                                <Typography variant="body2" component="div" sx={{fontWeight:600}}>PROPOSITION DE PASSAGE, REDOUBLEMENT ET D'EXCLUSION</Typography>
                            </div>
                            
                            <table className="table table-hover" style={{width:"100%"}}>
                                <thead>
                                    <tr>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse' }} colspan="6"></th>
                                        {students.length > 0  &&
                                            <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>{students[0].year.name}</th>
                                        }                                    
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}} colspan={CompositionYear.length}>Moyenne du</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center' }}>Moyenne</th>
                                    </tr>

                                    <tr>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Ordre</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>N° Matricule</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Prénom(s)</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Nom</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Sexe</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Date de naissance</th>
                                        {students.length > 0  &&
                                            <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Classe</th>
                                        }
                                        {CompositionYear.map((composition, index) => (
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}} >{(index + 1).toFixed(2)} Compos</td>
                                        ))}
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Annuelle</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Proposition</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Avis du DCAP</th>
                                    </tr>
                                </thead>

                                <tbody> 
                                    {students.map((student, index) => (
                                        <tr>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>{index + 1}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>{student.user.matricule}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>{student.user.first_name}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>{student.user.second_name}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>{student.student.sexe}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>{displaydate(student.student.birthdate)}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500, textAlign:'center' }}>{student.classe.name}</td>
                                            {CompositionYear.map((composition) => (
                                                <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500, textAlign:'center'}}>{GetMoyenneGenere(student.student_id, composition.composition_id).toFixed(2)}</td>
                                            ))}
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500, textAlign:'center' }}>{getMoyenneAnnuelle(student.student_id).toFixed(2)}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500, textAlign:'center' }}>{getNotApreciation(getMoyenneAnnuelle(student.student_id))}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500, textAlign:'center' }}> </td>
                                        </tr>
                                    ))}
                                </tbody> 
                            </table>
                        </div>
                    </Card>
                </Grid>

                <div  style={{ display: "none", width:"100%" }}> 
                    <div ref={el => (this.componentRef = el)}> 
                        <div style={{width:'100%'}}>
                            <div style={{width:'100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <div style={{ textAlign:'center', padding: 16}}>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{FirstLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>*****</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{SecondLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{ThirdLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>*****</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{FourthLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{FifthLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{SixthLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{SeventhLine()}</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>{EighthLine()}</Typography>
                                </div >

                                <div style={{ textAlign:'center', padding: 16}}>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>REPUBLIQUE DU MALI</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>Un Peuple- Un But- Une Foi</Typography>
                                    <Typography variant="body2" component="div" sx={{fontWeight:500}}>*****</Typography>
                                </div>                                        
                            </div>

                            <div style={{width:'100%', textAlign:'center', border: '2px solid black'}}>
                                <Typography variant="body2" component="div" sx={{fontWeight:600}}>PROPOSITION DE PASSAGE, REDOUBLEMENT ET D'EXCLUSION</Typography>
                            </div>
                            
                            <table className="table table-hover" style={{width:"100%"}}>
                                <thead>
                                    <tr>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse' }} colspan="6"></th>
                                        {students.length > 0  &&
                                            <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>{students[0].year.name}</th>
                                        }                                    
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}} colspan={CompositionYear.length}>Moyenne du</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center' }}>Moyenne</th>
                                    </tr>

                                    <tr>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Ordre</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>N° Matricule</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Prénom(s)</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Nom</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Sexe</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Date de naissance</th>
                                        {students.length > 0  &&
                                            <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Classe</th>
                                        }
                                        {CompositionYear.map((composition, index) => (
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}} >{(index + 1).toFixed(2)} Compos</td>
                                        ))}
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Annuelle</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Proposition</th>
                                        <th style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>Avis du DCAP</th>
                                    </tr>
                                </thead>

                                <tbody> 
                                    {students.map((student, index) => (
                                        <tr>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>{index + 1}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>{student.user.matricule}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>{student.user.first_name}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>{student.user.second_name}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>{student.student.sexe}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>{displaydate(student.student.birthdate)}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500, textAlign:'center' }}>{student.classe.name}</td>
                                            {CompositionYear.map((composition) => (
                                                <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500, textAlign:'center'}}>{GetMoyenneGenere(student.student_id, composition.composition_id).toFixed(2)}</td>
                                            ))}
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500, textAlign:'center' }}>{getMoyenneAnnuelle(student.student_id).toFixed(2)}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500, textAlign:'center' }}>{getNotApreciation(getMoyenneAnnuelle(student.student_id))}</td>
                                            <td style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500, textAlign:'center' }}> </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}