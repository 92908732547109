import React, { Component } from "react";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import {MainUrl} from '../settings'
import TextField from '@mui/material/TextField';

export default class SelectClasse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            classeYears: [],
            classe_id: null
        }
    }

    componentDidMount(){
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/classe-years', config)
        .then(response => {
            this.setState({ classeYears: response.data.data });
        }).catch(error => {}); 
    }

    handleChange(e) {
        const { name, value } = e.target;
        const cycle_id = this.state.classeYears.filter(c => (c["classe_id"] === e.target.value & c["activated"] === 1 ))[0]?.cycle_id
        this.setState(
            { [name]: value },
            () => {
                if (this.props.onChange) {
                    const data = {
                        'name': 'classe_id',
                        'classe_id': e.target.value,
                        'cycle_id': cycle_id
                    }
                    this.props.onChange(data)
                }                 
            }      
        );
    }

    render() {

        const {classeYears,  classe_id} = this.state
        
        let classes= []
        if(classeYears.filter(c => (c["year_id"] === this.props.year_id & c["activated"] === 1 ))[0] !== undefined){
            classes = classeYears.filter(c => c["year_id"] === this.props.year_id & c["activated"] === 1)
        }

        return (
            <Box sx={{display: 'flex', alignItems: 'center', ...this.props}}>
                <TextField
                    select
                    label="Classe"
                    name="classe_id"
                    onChange={this.handleChange.bind(this)}
                    size="small"
                    style={{ width: '100%' }}
                    value={classe_id || ''}
                >
                    {classes.map((option) => (
                        <MenuItem key={option.id} value={option.classe_id}>
                            {option.classe.name} | {option.cycle.name} | {option.year.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
        )

    }
}
