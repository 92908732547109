import React, { Component } from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import SelectClasse from '../SelectClasse'
import SelectComposition  from '../SelectComposition'
import SelectSubject from '../SelectSubject'
import IconButton from '@mui/material/IconButton';
import {MainUrl, SchoolName, FirstLine, SecondLine, ThirdLine, FourthLine, FifthLine, SixthLine, SeventhLine, EighthLine} from '../../settings'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteIcon from '@mui/icons-material/Delete';

export default class LessonsTracking extends Component {

    constructor(props) {
        super(props)
        this.state = {
            lecons: [],
            teachers: [],

            add: false,
            edit: false,
            show: false,

            id: null,
            subject_id: null,
            classe_id: null,
            cycle_id: null,
            composition_id: null,
            teacher_id: null,
            user_id: null,
            date: new Date(),
            hour: "",
            pci: "",
            pt: "",
            po: "",
            pe: "",
            tu: "",
            ee: "",
            cc: "",
            ev: "",
            og: "",

            matricule: "",
            first_name: "",
            second_name: "",
            classe:"",
            subject: "",

            loading: true,
            severity: "",
            message: "",
            isAlert:false,
            anchorEl: [],
            anchorElDate: null,
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Suivi de leçon"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/lecons', config)
        .then(response => {
            this.setState({ 
                lecons: response.data.data,
                teachers: response.data.teachers,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClickDate(event){this.setState({anchorElDate: event.currentTarget})}

    handleSelectDate(date){ this.setState({date: date, anchorElDate: null}) }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleChangeClasse(data){
        if(data.name === 'classe_id'){
            this.setState({ 
                classe_id: data.classe_id,
                cycle_id: data.cycle_id
            }) 
        }        
    }

    handleChangeComposition(data){
        if(data.name === 'composition_id'){
            this.setState({ composition_id: data.value })
        }        
    }

    handleChangeSubjectYear(data){
        if(data.name === 'subject_id'){
            this.setState({ 
                subject_id: data.value,
            }) 
        }        
    }

    handleSubmit(event) {
        event.preventDefault()       
        this.setState({loading: true})   

        let elem = this.state.teachers.filter(c => c["id"] === this.state.teacher_id)[0]

        const bodyParameters = {
            year_id: this.props.year_id,
            teacher_id: this.state.teacher_id,
            classe_id: this.state.classe_id,
            composition_id: this.state.composition_id,
            subject_id: this.state.subject_id,
            user_id: elem.user_id,
            date: this.state.date,
            hour: this.state.hour,            
            pci: this.state.pci,
            pt: this.state.pt,
            po: this.state.po,
            pe: this.state.pe,
            tu: this.state.tu,
            ee: this.state.ee,
            cc: this.state.cc,
            ev: this.state.ev,
            og: this.state.og
            
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/lecon',
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()           
            }                       
        }).catch(error => {
            this.setState({
                add: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    handleSubmitEdit(event){
        event.preventDefault()       
        this.setState({loading: true})   

        let elem = this.state.teachers.filter(c => c["id"] === this.state.teacher_id)[0]

        const bodyParameters = {
            year_id: this.props.year_id,
            teacher_id: this.state.teacher_id,
            classe_id: this.state.classe_id,
            composition_id: this.state.composition_id,
            subject_id: this.state.subject_id,
            user_id: elem.user_id,
            date: this.state.date,
            hour: this.state.hour,            
            pci: this.state.pci,
            pt: this.state.pt,
            po: this.state.po,
            pe: this.state.pe,
            tu: this.state.tu,
            ee: this.state.ee,
            cc: this.state.cc,
            ev: this.state.ev,
            og: this.state.og
            
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        axios.put(MainUrl() + '/api/v1/lecon/' + this.state.id,
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()            
            }                       
        }).catch(error => {
            this.setState({
                edit: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });        
    }

    show(id){
        this.setState({loading: true}) 
        const item = this.state.lecons.filter(c => c["id"] === id)[0]
        this.setState({ 
            classe: item.classe.name,
            subject:item.subject.name,
            user_id: item.user_id,
            matricule: item.user.matricule,
            first_name: item.user.first_name,
            second_name: item.user.second_name,
            subject_id: item.subject_id,
            classe_id: item.classe_id,
            cycle_id: item.cycle_id,
            composition_id: item.composition_id,
            teacher_id: item.teacher_id,
            date: new Date(item.date),
            hour: item.hour,
            pci: item.pci,
            pt: item.pt,
            po: item.po,
            pe: item.pe,
            tu: item.tu,
            ee: item.ee,
            cc: item.cc,
            ev: item.ev,
            og: item.og,

            show: true,
            loading: false,
            anchorEl: [], 
        })
    }

    edit(id){
        this.setState({loading: true}) 
        const item = this.state.lecons.filter(c => c["id"] === id)[0]
        this.setState({ 
            id: id,
            classe: item.classe.name,
            subject:item.subject.name,
            user_id: item.user_id,
            matricule: item.user.matricule,
            first_name: item.user.first_name,
            second_name: item.user.second_name,
            subject_id: item.subject_id,
            classe_id: item.classe_id,
            cycle_id: item.cycle_id,
            composition_id: item.composition_id,
            teacher_id: item.teacher_id,
            date: new Date(item.date),
            hour: item.hour,
            pci: item.pci,
            pt: item.pt,
            po: item.po,
            pe: item.pe,
            tu: item.tu,
            ee: item.ee,
            cc: item.cc,
            ev: item.ev,
            og: item.og,

            edit: true,
            loading: false,
            anchorEl: [], 
        })
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/lecon/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.componentDidMount()     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {   

        const {
            lecons,
            teachers,
            add,
            edit,
            show,
            classe_id,
            cycle_id,
            composition_id,
            subject_id,
            teacher_id,
            user_id,
            matricule,
            first_name,
            second_name,
            classe,
            subject,
            date,
            hour,
            pci,
            pt,
            po,
            pe,
            tu,
            ee,
            cc,
            ev,
            og,
            loading,
            severity,
            message,
            isAlert,
            anchorElDate,
            anchorEl
        } = this.state   

        let column = [
            { 
                field: 'matricule', 
                headerName: 'Matricule',
                flex: 1/2,
                renderCell: (cellValues) => { return cellValues.row.user.matricule}
            },
            { 
                field: 'first_name', 
                headerName: 'Prénom',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.user.first_name}
            },
            { 
                field: 'second_name', 
                headerName: 'Nom de famille',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.user.second_name}
            },
            { 
                field: 'classe', 
                headerName: 'Classe',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.classe.name}
            },
            { 
                field: 'subject', 
                headerName: 'Matière',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.subject.name}
            },
            { 
                field: 'date', 
                headerName: 'Date',
                flex: 1,
                renderCell: (cellValues) => { return displaydate(new Date(cellValues.row.date))}
            },
            { 
                field: 'hour', 
                headerName: 'Heure',
                flex: 2/3,
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                        width:150
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={() => { this.show(cellValues.row.id) }}>
                                    <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Details
                                </MenuItem>

                                {!this.props.accesses.write ||
                                    <MenuItem onClick={() => { this.edit(cellValues.row.id) }}>
                                        <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                    </MenuItem>
                                }

                                {!this.props.accesses.remove ||
                                    <MenuItem onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(cellValues.row.id)}}} >
                                        <ListItemIcon sx={{color: 'red'}}><DeleteIcon fontSize="small" /></ListItemIcon>Supprimer
                                    </MenuItem>
                                }
                            </Menu>
                        </React.Fragment>
                    )
                }
            }
        ]

        let DataLecons = lecons
        if(this.props.year_id !== null){
            DataLecons = DataLecons.filter(c => c["year_id"] === this.props.year_id)
        }
        if(classe_id !== null){
            DataLecons = DataLecons.filter(c => c["classe_id"] === classe_id)
        }
        if(composition_id !== null){
            DataLecons = DataLecons.filter(c => c["composition_id"] === composition_id)
        }
        if(subject_id !== null){
            DataLecons = DataLecons.filter(c => c["subject_id"] === subject_id)
        }

        if(this.props.search !== ''){
            let result = []
            DataLecons.map((elem, index) => {
                elem.search = elem.user.matricule + ' ' + elem.user.first_name + ' ' + elem.user.second_name + ' ' + elem.classe.name + ' ' + elem.subject.name + ' ' + elem.hour
                result = result.concat(elem)
            })
            DataLecons = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        const displaydate = (value) => {
            var date = new Date(value);
            if(date.getMonth() == 0){
                return date.getDate() + "/01/" + date.getFullYear()
            }
            if(date.getMonth() == 1){
                return date.getDate() + "/02/" + date.getFullYear()
            }
            if(date.getMonth() == 2){
                return date.getDate() + "/03/" + date.getFullYear()
            }
            if(date.getMonth() == 3){
                return date.getDate() + "/04/" + date.getFullYear()
            }
            if(date.getMonth() == 4){
                return date.getDate() + "/05/" + date.getFullYear()
            }
            if(date.getMonth() == 5){
                return date.getDate() + "/06/" + date.getFullYear()
            }
            if(date.getMonth() == 6){
                return date.getDate() + "/07/" + date.getFullYear()
            }
            if(date.getMonth() == 7){
                return date.getDate() + "/08/" + date.getFullYear()
            }
            if(date.getMonth() == 8){
                return date.getDate() + "/09/" + date.getFullYear()
            }
            if(date.getMonth() == 9){
                return date.getDate() + "/10/" + date.getFullYear()
            }
            if(date.getMonth() == 10){
                return date.getDate() + "/11/" + date.getFullYear()
            }
            if(date.getMonth() == 11){
                return date.getDate() + "/12/" + date.getFullYear()
            }
        }

        return(
            <Grid container spacing={3}> 
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end", alignItems: 'center'}}>
                    <SelectClasse
                        year_id={this.props.year_id} 
                        onChange={this.handleChangeClasse.bind(this)} 
                        width={200} 
                        mr={2} 
                    />

                    <SelectComposition 
                        year_id={this.props.year_id} 
                        classe_id={classe_id} 
                        cycle_id={cycle_id}
                        onChange={this.handleChangeComposition.bind(this)}
                        width={200}
                        mr={2} 
                    />

                    <SelectSubject
                        year_id={this.props.year_id} 
                        classe_id={classe_id} 
                        composition_id={composition_id}
                        onChange={this.handleChangeSubjectYear.bind(this)}
                        width={200}
                        mr={2} 
                    />

                    {!this.props.accesses.write ||
                        <Button 
                            disabled={this.props.year_id === null || classe_id === null || composition_id === null || subject_id === null}
                            variant="contained"
                            onClick={() => this.setState({add: true})} 
                            sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff'}}
                        >
                            Ajouter un suivi de leçon
                        </Button>
                    }
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:620, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={DataLecons}
                            columns={column}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Ligne par page'
                                }
                            }}
                        />
                    </Card>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                    scroll={'paper'}
                >
                    <DialogTitle>Ajouter un suivi de leçon</DialogTitle>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{maxHeight: '80vh'}}> 
                        <DialogContent dividers={true}>
                            <DialogContentText tabIndex={-1} >
                                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            required
                                            select
                                            label="Professeurs"
                                            name="teacher_id"
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                            style={{ width: '100%' }}
                                            value={teacher_id || ''}
                                        >
                                            {teachers.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.user.matricule} | {option.user.first_name} {option.user.second_name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={6}>                                    
                                        <TextField
                                            id="outlined-required"
                                            label="Date"
                                            style={{ width: '100%' }}
                                            value={displaydate(date)}
                                            onClick={this.handleClickDate.bind(this)} 
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Heure"
                                            name="hour"
                                            style={{ width: '100%' }}
                                            value={hour}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700, mb:1 }}>I. PRESENTATION GENERALE DU COURS</Typography> 

                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Présentation du centre d'intérêt"
                                            multiline
                                            rows={2}
                                            name="pci"
                                            style={{ width: '100%' }}
                                            value={pci}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid> 

                                    <Grid item xs={12} md={12}>
                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Présentation du thème"
                                            multiline
                                            rows={2}
                                            name="pt"
                                            style={{ width: '100%' }}
                                            value={pt}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>  

                                    <Grid item xs={12} md={12}>
                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Présentation des objectifs"
                                            multiline
                                            rows={2}
                                            name="po"
                                            style={{ width: '100%' }}
                                            value={po}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>  

                                    <Grid item xs={12} md={12}>
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700, mb:1 }}>II. INTRODUCTION DE LA LEÇON</Typography> 

                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Pré évaluation(Pré requis des élèves"
                                            multiline
                                            rows={4}
                                            name="pe"
                                            style={{ width: '100%' }}
                                            value={pe}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>  

                                    <Grid item xs={12} md={12}>
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700, mb:1 }}>III. STRATEGIES</Typography> 

                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Techniques utilisée"
                                            multiline
                                            rows={2}
                                            name="tu"
                                            style={{ width: '100%' }}
                                            value={tu}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>  

                                    <Grid item xs={12} md={12}>
                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Echanges avec les élèves / Feed-back"
                                            multiline
                                            rows={2}
                                            name="ee"
                                            style={{ width: '100%' }}
                                            value={ee}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid> 

                                    <Grid item xs={12} md={12}>
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700, mb:1 }}>IV. CONTENUS DES COURS (QUALITE DES CONTENUS)</Typography> 

                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Contenus des cours (qualité des contenus)"
                                            multiline
                                            rows={8}
                                            name="cc"
                                            style={{ width: '100%' }}
                                            value={cc}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>  

                                    <Grid item xs={12} md={12}>
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700, mb:1 }}>V. EVALUATION</Typography> 

                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Evaluation"
                                            multiline
                                            rows={4}
                                            name="ev"
                                            style={{ width: '100%' }}
                                            value={ev}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>  

                                    <Grid item xs={12} md={12}>
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700, mb:1 }}>VI. OBSERVATION GENERALE</Typography> 

                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Observation générale"
                                            multiline
                                            rows={8}
                                            name="og"
                                            style={{ width: '100%' }}
                                            value={og}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid> 
                                </Grid>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                type="submit" 
                                fullWidth
                                variant="contained" 
                                sx={{
                                    m:0,
                                    p:0,
                                    color:"white", 
                                    textTransform: 'none', 
                                    fontSize:20,
                                    backgroundColor:'#888da0',  
                                    borderRadius:0, 
                                    ':hover':{backgroundColor:'#306bff'} 
                                }}
                            >
                                Cliquer pour ajouter
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <DialogTitle>Modifier un suivi de leçon</DialogTitle>

                    <form role="form"  onSubmit={this.handleSubmitEdit.bind(this)} style={{maxHeight: '80vh'}}> 
                        <DialogContent dividers={true}>
                            <DialogContentText tabIndex={-1} >
                                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            required
                                            select
                                            label="Professeurs"
                                            name="teacher_id"
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                            style={{ width: '100%' }}
                                            value={teacher_id || ''}
                                        >
                                            {teachers.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.user.matricule} | {option.user.first_name} {option.user.second_name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={6}>                                    
                                        <TextField
                                            id="outlined-required"
                                            label="Date"
                                            style={{ width: '100%' }}
                                            value={displaydate(date)}
                                            onClick={this.handleClickDate.bind(this)} 
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Heure"
                                            name="hour"
                                            style={{ width: '100%' }}
                                            value={hour}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700, mb:1 }}>I. PRESENTATION GENERALE DU COURS</Typography> 

                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Présentation du centre d'intérêt"
                                            multiline
                                            rows={2}
                                            name="pci"
                                            style={{ width: '100%' }}
                                            value={pci}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid> 

                                    <Grid item xs={12} md={12}>
                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Présentation du thème"
                                            multiline
                                            rows={2}
                                            name="pt"
                                            style={{ width: '100%' }}
                                            value={pt}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>  

                                    <Grid item xs={12} md={12}>
                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Présentation des objectifs"
                                            multiline
                                            rows={2}
                                            name="po"
                                            style={{ width: '100%' }}
                                            value={po}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>  

                                    <Grid item xs={12} md={12}>
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700, mb:1 }}>II. INTRODUCTION DE LA LEÇON</Typography> 

                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Pré évaluation(Pré requis des élèves"
                                            multiline
                                            rows={4}
                                            name="pe"
                                            style={{ width: '100%' }}
                                            value={pe}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>  

                                    <Grid item xs={12} md={12}>
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700, mb:1 }}>III. STRATEGIES</Typography> 

                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Techniques utilisée"
                                            multiline
                                            rows={2}
                                            name="tu"
                                            style={{ width: '100%' }}
                                            value={tu}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>  

                                    <Grid item xs={12} md={12}>
                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Echanges avec les élèves / Feed-back"
                                            multiline
                                            rows={2}
                                            name="ee"
                                            style={{ width: '100%' }}
                                            value={ee}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid> 

                                    <Grid item xs={12} md={12}>
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700, mb:1 }}>IV. CONTENUS DES COURS (QUALITE DES CONTENUS)</Typography> 

                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Contenus des cours (qualité des contenus)"
                                            multiline
                                            rows={8}
                                            name="cc"
                                            style={{ width: '100%' }}
                                            value={cc}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>  

                                    <Grid item xs={12} md={12}>
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700, mb:1 }}>V. EVALUATION</Typography> 

                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Evaluation"
                                            multiline
                                            rows={4}
                                            name="ev"
                                            style={{ width: '100%' }}
                                            value={ev}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>  

                                    <Grid item xs={12} md={12}>
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700, mb:1 }}>VI. OBSERVATION GENERALE</Typography> 

                                        <TextField  
                                            id="outlined-multiline-static"
                                            label="Observation générale"
                                            multiline
                                            rows={8}
                                            name="og"
                                            style={{ width: '100%' }}
                                            value={og}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid> 
                                </Grid>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button  
                                type="submit" 
                                    fullWidth
                                    variant="contained" 
                                    sx={{
                                        m:0,
                                        p:0,
                                        color:"white", 
                                        textTransform: 'none', 
                                        fontSize:20,
                                        backgroundColor:'#888da0',  
                                        borderRadius:0, 
                                        ':hover':{backgroundColor:'#306bff'} 
                                    }}
                            >
                                Cliquer pour modifier
                            </Button> 
                        </DialogActions>                          
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={show}
                    onClose={() => this.setState({show: false})}
                    scroll={'paper'}
                >
                    <DialogTitle sx={{display: 'flex', justifyContent:'center'}}>FICHER DE SUIVI DE LECON</DialogTitle>

                    <DialogContent dividers={true}>
                        <DialogContentText tabIndex={-1} >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end", alignItems: 'center'}}>
                                    <Typography color="text.secondary" sx={{fontSize:16}}>Bamako, le {displaydate(new Date(date))}</Typography>                                      
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"space-between", alignItems: 'center'}}>
                                    <Typography color="text.secondary" sx={{fontSize:16}}>CLASSE: {classe}</Typography>  
                                    <Typography color="text.secondary" sx={{fontSize:16}}>DISCIPLINE: {subject}</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}>Heure: {hour}</Typography>                                     
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16}}>PROFESSEUR: {matricule} | {first_name} {second_name}</Typography> 
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700}}>I. PRESENTATION GENERALE DU COURS</Typography> 
                                </Grid> 

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:600}}> Présentation du centre d'intérêt</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {pci}</Typography>    
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:600}}> Présentation du thème</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {pt}</Typography>   
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:600}}> Présentation des objectifs</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {po}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700}}>II. INTRODUCTION DE LA LEÇON</Typography> 
                                </Grid> 

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:600}}> Pré évaluation(Pré requis des élèves)</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {pe}</Typography>    
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700}}>III. STRATEGIES</Typography> 
                                </Grid> 

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:600}}> Techniques utilisée</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {tu}</Typography>    
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:600}}> Echanges avec les élèves / Feed-back</Typography> 
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {ee}</Typography>    
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700}}>IV. CONTENUS DES COURS (QUALITE DES CONTENUS)</Typography> 
                                </Grid> 

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {cc}</Typography>    
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700}}>V. EVALUATION</Typography> 
                                </Grid> 

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {ev}</Typography>    
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16, fontWeight:700}}>VI. OBSERVATION GENERALE</Typography> 
                                </Grid> 

                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography color="text.secondary" sx={{fontSize:16}}> {og}</Typography>    
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button fullWidth onClick={() => this.setState({show: false})}>Fermer</Button>
                    </DialogActions>
                </Dialog>

                <Menu
                    elevation={12}
                    id="basic-menu"
                    anchorEl={anchorElDate}
                    open={Boolean(anchorElDate)}
                    onClose={() => {this.setState({ anchorElDate: null})}}
                    PaperProps={{
                        sx: {
                            mt:4,
                            ml: '-16px',
                        }
                    }}
                >
                    <Calendar
                        date={date}
                        onChange={this.handleSelectDate.bind(this)}
                        locale={locales['fr']}
                    />
                </Menu>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />  
            </Grid>
        )
    }

}