import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dropzone from 'react-dropzone'
import DescriptionIcon from '@mui/icons-material/Description';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Masonry from '@mui/lab/Masonry';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';

export default class AlbumYears extends Component { 

    constructor(props) {
        super(props)
        this.state = {
            medias: [],
            albums: [],

            add: false,
            show: false,
            album_id: null,
            selectedFile: [],

            severity: "",
            message: "",
            isAlert:false,
            loading: true,
            anchorEl: []
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Galerie"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/media', config)
        .then(response => {
            this.setState({ 
                medias: response.data.data,
                albums: response.data.albums,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){this.setState({isAlert:false}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    fileDropImport(acceptedFiles){ this.setState({ selectedFile: acceptedFiles[0] })}

    handleSubmit(event) {
        event.preventDefault()
        if(this.state.selectedFile.length === 0){
            this.setState({ 
                severity: 'error',
                message: "Faites glisser et déposez le(s) fichier(s), ou cliquez pour sélectionner le(s) fichiers",
                isAlert: true
            })
        }else{
            this.setState({loading: true})   
            const tokenJson = localStorage.getItem("TOKEN");
            const token = JSON.parse(tokenJson);        
            const config = {
                headers: {'Authorization': 'Bearer '+token}
            }
            const bodyParameters = new FormData();
            bodyParameters.append("album_id", this.state.album_id)
            bodyParameters.append("year_id", this.props.year_id)
            bodyParameters.append("files", this.state.selectedFile)
            axios.post(MainUrl() +  '/api/v1/media', 
                bodyParameters,
                config
            ).then(response => {           
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()                 
            }).catch(error => {
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'error',
                    message: error.response.data?.message || "Une erreur inattendue s'est produite",
                    isAlert: true
                });
            });
        }
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/media/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.componentDidMount()
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {    

        const {
            medias,
            albums,
            add,
            show,
            album_id,
            selectedFile,
            loading,
            severity,
            message,
            isAlert
        } = this.state

        let media = medias
        if(this.props.year_id !== null){
            media = media.filter(c => c["year_id"] === this.props.year_id)
        }
        if(album_id !== null){
            media = media.filter(c => c["album_id"] === album_id)
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    {!this.props.accesses.write || 
                        <Button 
                            variant="contained"
                            onClick={() => this.setState({add: true})} 
                            sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff', mr:2}}
                            disabled={!this.props.accesses.write} 
                        >
                            Importer un media
                        </Button> 
                    }

                    {!this.props.accesses.remove || 
                        <Button 
                            variant="contained" 
                            onClick={() => this.setState({show: true})} 
                            sx={{background:'#eff0f2', color:'black', boxShadow: 10, borderRadius:1, ':hover':{background:'red', color:'white'}}}
                            disabled={!this.props.accesses.remove}
                        >
                            Supprimer un media
                        </Button>
                    }

                       
                </Grid>

                <Grid item xs={12} sm={12} md={12} align="center" >
                    <Breadcrumbs aria-label="breadcrumb" align="center" style={{width:"100%", display: 'flex', justifyContent: 'center'}}>
                        <Button variant="text" onClick={() => this.setState({album_id: null})} >Toutes</Button>
                        {albums.map((elem) => (<Button variant="text" onClick={() => this.setState({album_id: elem.id})} >{elem.name}</Button>))}
                    </Breadcrumbs>
                </Grid>

                <Grid item xs={12} sm={12} md={12} align="center" >
                    <Masonry columns={4} spacing={2}>
                        {media.map((item, index) => (
                            <Card elevation={16}  sx={{backgroundColor:'#ffffff', borderBottom:'1px solid #000000de', borderRadius:2}} key={index}>
                                <a href={MainUrl() + '/' + item.file} target="_blank">
                                    <img
                                        src={MainUrl() + '/' + item.file}
                                        loading="lazy"
                                        style={{
                                            borderBottomLeftRadius: 4,
                                            borderBottomRightRadius: 4,
                                            display: 'block',
                                            width: '100%',
                                        }}
                                    />
                                </a>                                  
                            </Card>
                        ))}
                    </Masonry>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    scroll='paper'
                    onClose={() => this.setState({add: false})}                    
                >
                    <DialogTitle id="scroll-dialog-title" style={{display: 'flex', alignItems:'center', flexDirection:'row', justifyContent:'space-between',}}>
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Importer un media
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </DialogTitle>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} >
                        <DialogContent dividers>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>   
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        select
                                        label="Album"
                                        name="album_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                    >
                                        {albums.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid> 

                                <Grid item xs={12} md={12} style={{textAlign: "center"}}> 
                                    <Dropzone onDrop={acceptedFiles  => this.fileDropImport(acceptedFiles)}>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    {selectedFile.length === 0 ? 
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                            <p>Faites glisser et déposez un fichier ici, ou cliquez pour sélectionner unle(s) fichier(s)</p>
                                                        </React.Fragment>
                                                    :
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  />
                                                            
                                                            <Typography component="p" variant="p" color="text.secondary" sx={{}}>
                                                                {selectedFile.path}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>                        
                            <Button 
                                variant="contained"
                                fullWidth
                                type="submit"
                            >
                                Importer le(s) fichier(s)
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={show}
                    scroll='paper'
                    onClose={() => this.setState({show: false})}                    
                >
                    <DialogTitle id="scroll-dialog-title" style={{display: 'flex', alignItems:'center', flexDirection:'row', justifyContent:'space-between',}}>
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Supprimer un media
                        </Typography>

                        <IconButton onClick={() =>  this.setState({show: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </DialogTitle>

                    <DialogContent dividers>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>   
                            <Grid item xs={12} md={12}>
                                <table className="table table-hover" style={{width:"100%", textAlign:'left'}}>
                                    <thead>
                                        <tr>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Miniature</Typography></th>
                                            <th scope="col" style={{textAlign:'center'}} ><Typography color="text.secondary" sx={{fontSize:16 }}>Actions</Typography></th>
                                        </tr>
                                    </thead>

                                    <tbody> 
                                        {media.map((item) => (
                                            <tr>
                                                <td><Avatar alt={item.file} src={MainUrl() + '/' + item.file} /></td>
                                                <td style={{textAlign:'center'}}>
                                                    <Button
                                                        onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(item.id)}}}
                                                        variant="contained"
                                                        sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'red', color:'white'}}}
                                                    >
                                                        Supprimer
                                                    </Button>
                                                </td>
                                            </tr>                                            
                                        ))}
                                    </tbody>
                                </table>                                
                            </Grid> 
                        </Grid>
                    </DialogContent>
                </Dialog>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}
